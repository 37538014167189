import React, {useState} from 'react'
import {BtnText} from '../Btn/Btn'
import validator from 'validator'
import {dischargeStringUser} from '../../api/user'
import {setCartProduct} from '../../api/basket'

const DevPanel = () => {
    const resetString = async () => {
        let email: string | null = 'tester@divasoft.ru'
        const tester = window.confirm(`Тебе нужно тестовый акк встряхнуть? ${email}`)
        if (!tester) email = prompt('Вводи почту этого бедолаги, щас сбросим', '')

        if (email) {
            if (!validator.isEmail(email)) {
                return alert('Сшышишь, дядя, не гони. Почту проверь')
            }
            try {
                const result = await dischargeStringUser(email)
                const checkword = result.data.checkword
                navigator.clipboard.writeText(checkword)
                    .then(() => alert(`Вставили тебе ${checkword}. В буфер`))
                    .catch(() => alert(`Ничо не вставил. Сам вставляй: ${checkword}`))
            } catch (e) {
                console.log(e.message)
            }

        }
    }

    const addCart = async () => {
        const id = prompt('Введите id', '')
        if (!id) return alert('Ты не ввел id')
        try {
            const result = await setCartProduct(parseInt(id), 1)
            console.log(result)
        } catch (e) {

        }
    }

    const [open, setOpen] = useState(false)
    return (
        <div style={{position: 'absolute', top: 0, left: 0, zIndex: 20}}>
            <div style={{background: '#fff'}} onClick={() => setOpen(!open)}>
                {open ? 'закрыть ссылки' : 'открыть ссылки'}
            </div>
            {open &&
            <div style={{background: '#fff'}}>
                <div><BtnText action={resetString}>Сбросить контрольную строку</BtnText></div>
                <div><BtnText action={addCart}>Добавить в корзину</BtnText></div>
                <div><BtnText link={'/auth'} size='small'>Авторизация</BtnText></div>
                <div><BtnText link={'/registration'} size='small'>Регистрация</BtnText></div>
                <div><BtnText link={'/registration/invite'} size='small'>Регистрация по приглашению</BtnText></div>
                <div><BtnText link={'/personal'} size='small'>Профиль</BtnText></div>

                <div><BtnText link={'/personal/documents/'} size='small'>Документы</BtnText></div>
                <div><BtnText link={'/personal/documents/1'} size='small'>Документ УПД</BtnText></div>
                <div><BtnText link={'/personal/documents/1/1'} size='small'>Документ УКД</BtnText></div>

                <div><BtnText link={'/personal/orders/'} size='small'>Заказы</BtnText></div>
                <div><BtnText link={'/personal/orders/1'} size='small'>Заказ детальный</BtnText></div>

                <div><BtnText link={'/personal/returns/create'} size='small'>Создать заявку на возврат</BtnText></div>
                <div><BtnText link={'/personal/returns/'} size='small'>Возвраты</BtnText></div>
                <div><BtnText link={'/personal/returns/1'} size='small'>Заявка на возврат</BtnText></div>
                <div><BtnText link={'/cart/'} size='small'>Корзина</BtnText></div>
                <div><BtnText link={'/carts/'} size='small'>Список корзин</BtnText></div>
                <div><BtnText link={'/checkout/'} size='small'>Оформить заказ</BtnText></div>
                <div><BtnText link={'/catalog/'} size='small'>Каталог</BtnText></div>
                <div><BtnText link={'/search/'} size='small'>Поиск</BtnText></div>
                <div><BtnText link={'/search-results/'} size='small'>Результаты поиска</BtnText></div>
                <div><BtnText link={'/seller/price-lists/'} size='small'>Прайс листы</BtnText></div>
                <div><BtnText link={'/seller/orders/'} size='small'>Поставщик заказы</BtnText></div>
                <div><BtnText link={'/seller/orders/1'} size='small'>Поставщик заказ детальный</BtnText></div>
                <div><BtnText link={'/seller/shipment/'} size='small'>Ожидают отгрузку</BtnText></div>
                <div><BtnText link={'/seller/return-orders/'} size='small'>Заказы на возврат</BtnText></div>
                <div><BtnText link={'/seller/return-orders/1'} size='small'>Детальный заказ на возврат</BtnText></div>
                <div><BtnText link={'/seller/returns/'} size='small'>Поставщик возвраты</BtnText></div>
                <div><BtnText link={'/seller/documents/'} size='small'>Поставщик документы</BtnText></div>
                <div><BtnText link={'/seller/documents/upload'} size='small'>Поставщик документ загрузить</BtnText></div>
                <div><BtnText link={'/seller/stats/'} size='small'>Поставщик статистика</BtnText></div>
            </div>
            }
        </div>
    )
}

export default DevPanel
