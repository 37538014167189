import React, {ChangeEvent, createContext, useRef, useState} from 'react'
import {debounce} from '@material-ui/core/utils'
import Autocomplete from './Autocomplete'
import styles from './City.module.scss'
import {fetchCity} from '../../api/main'

type CitySelectProps = {
	selectedHandle: (bx_id: string) => void
	changeHandle?: (bx_id: string) => void
	className?: string
	label?: boolean
}

export const CitySelectContext = createContext<{
	focus: boolean
	suggestions: any[]
	onSelect: (cityObj: any) => void
	styles: any
}
>({
	focus: false,
	suggestions: [],
	onSelect: (cityObj: any) => {},
	styles: styles
})

const CitySelect = ({selectedHandle, changeHandle, className = '', label = true}: CitySelectProps) => {
	const [focus, setFocus] = useState<boolean>(false)
	const [suggestions, setSuggestions] = useState<any[]>([])
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (changeHandle) {
			changeHandle('')
		}
		if (e.target.value === '') {
			setSuggestions([])
		}
		else {
			setSuggestions([{value: 'Загрузка...'}])
			fetchCity(e.target.value)
				.then(r => {
					if (r.data) {
						setSuggestions(r.data)
					}
					else {
						setSuggestions([])
					}
				})
		}
	}
	const ref = useRef<HTMLInputElement>(null)
	const onSelect = (cityObj: any) => {
		if (ref && ref.current) {
			ref.current.value = cityObj.value
			selectedHandle(cityObj.data.bx_id)
		}
	}
	return (
		<CitySelectContext.Provider value={{focus, suggestions, onSelect: onSelect, styles}}>
			<div className={`${className} ${styles.citySelect} ${focus ? styles.active : ''}`}>
				<div className='inputWrap'>
					{label && <div className='inputWrap__label color--black'>Город</div>}
					<input
						ref={ref}
						name='city'
						onFocus={() => setFocus(true)}
						onBlur={() => setFocus(false)}
						onChange={debounce(handleChange, 200)}
						type='text'
						placeholder='Введите название города'
						autoComplete='off'
						className='input'/>
					{suggestions.length > 0 && <Autocomplete/>}
				</div>
			</div>
		</CitySelectContext.Provider>
	)
}

export default CitySelect