import React, {FC, ReactElement} from 'react'
import {createPortal} from 'react-dom'
import DefaultLayout from '../../layouts/Modal/DefaultLayout'
import {ModalProps, TriggerType} from './types'

const Modal: FC<ModalProps & TriggerType> = ({
    title,
    description,
    message,
    className,
    popupClass,
    typeHeader,
    setState,
    state,
    children
}) => {
    const result: ReactElement | null = state ? (
        <DefaultLayout
            title={title}
            description={description}
            message={message}
            typeHeader={typeHeader}
            setState={setState}
            className={className}
            popupClass={popupClass}
        >
            {children}
        </DefaultLayout>
    ) : null

    const app: HTMLElement | null = document.getElementById('root')
    if (app) {
        return createPortal(result, app)
    }
    return result
}

export default Modal