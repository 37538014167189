import React, {FC, FormEvent, SyntheticEvent, useContext} from 'react'
import IconSearch from '../../../../Svg/IconSearch'
import {BtnPrimary} from '../../../../Btn/Btn'
import {StylesContext} from './Search'

type SearchInputProps = {
	onChange: (r: string) => void
	onSubmit: (e: FormEvent<HTMLFormElement>) => void
	ref: React.Ref<HTMLInputElement>
}

const SearchInput:FC<SearchInputProps> = React.forwardRef(({onChange, onSubmit}, ref) => {
	const styles = useContext(StylesContext)

	return (
		<form className={styles.search__form} onSubmit={(e) => onSubmit(e)}>
			<IconSearch className={styles.search__icon}/>
			<input
				ref={ref}
				name='search'
				className={styles.search__input}
				type='search'
				placeholder='Введите номер детали'
				autoComplete='off'
				onChange={(e) => onChange(e.target.value)}/>
			<BtnPrimary type='submit' addClass={styles.search__btn}>Найти</BtnPrimary>
		</form>
	)
})

export default SearchInput