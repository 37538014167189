import {makeAutoObservable, runInAction, toJS} from 'mobx'
import {generateFormData} from '../common/utils'
import {TRegistrationEntity, TRegistrationIndividual, TRegistrationInvite} from '../types/TRegistration'
import {authorize, deauthorize, fetchUser, registration} from '../api/user'
import {setCartProduct} from '../api/basket'

class PersonalStore {
	bitrixAllLoaded = false
	bitrix_sessid = null
	bitrixScriptsLoading = [] as {loaded: boolean}[]
	// Объект для построения корзины
	cartTable:any = {}
	authorized = false
	validAuth = false
	rememberMe = false
	authErrors = []
	inviteLink = ''
	inviteData:any = null

	constructor() {
		makeAutoObservable(this)
	}

	auth = async (data: any) => {
		const formData = generateFormData(data)
		authorize(formData)
			.then(r => {
				runInAction(() => {
					this.authErrors = r.data.errors

					// FIXME хак для установки печенек
					r.data.data.multi.forEach((item: string) => new Image().src = item)

					// FIXME изменить чтобы запрашивалась в кажом запрсое
					if (this.bitrixAllLoaded) {
						this.bitrix_sessid = window.BX.message('bitrix_sessid')
					}

					this.getUser()

					console.log(toJS(r.data))
				})
			})
			.catch(err => console.log(err))
	}

	getUser = async () => {
		fetchUser()
			.then(r => {
				console.log('user data')
				console.log(toJS(r.data))
				runInAction(() => {
					this.authorized = r.data.data.auth
					this.cartTable = this.formatterCart(r.data.data.basket)
					this.inviteLink = r.data.data.personal.invite
				})
			})
			.catch(err => console.log(err))
	}

	logout = async () => {
		deauthorize()
			.then(r => {
				console.log(toJS(r))
				runInAction(() => {
					this.authorized = false
				})
			})
			.catch(err => console.log(err))
	}

	setRememberMe = (name: string, checked: boolean) => {
		runInAction(() => {
			this.rememberMe = checked
		})
	}



	registration = async (data:TRegistrationIndividual | TRegistrationEntity | TRegistrationInvite) => {
		return await registration(data)
	}

	formatterCart = (cartTable:any) => {
		if (cartTable.rows) {
			cartTable.rows.forEach((item:any) => {
				item.uid = item.id
				delete item.id
			})
		}
		return cartTable
	}

	delete = (uid: number) => {
		if (!uid) {
			return null
		}
		setCartProduct(uid, 0)
			.then((r) => this.cartTable = this.formatterCart(r))
	}

}

export default PersonalStore