import React, {FC} from 'react'
import {Link} from 'react-router-dom'

import style from './Btn.module.scss'

type BtnProps = {
    link?: string,
    action?: (event?: any) => void,
    disabled?: boolean,
    addClass?: string
}

type InnerBtnProps = BtnProps & {
    className: string
    type?: 'submit' | 'button'
}

type OuterBtnProps = BtnProps & {
    size?: 'small' | 'medium'
    type?: 'submit' | 'button'
}

const Btn:FC<InnerBtnProps> = ({children, link, action, type, className, disabled}) => {
    return (
        link ?
            <Link to={link}><button className={`${className} ${disabled ? style.disabled : ''}`}>{children}</button></Link>
            :
            <button type={type} className={`${className} ${disabled ? style.disabled : ''}`} onClick={action}>{children}</button>
    )
}

export const BtnPrimary:FC<OuterBtnProps> = ({
    children,
    link,
    action = () => false,
    size,
    disabled = false,
    addClass,
    type = 'button'
}) => {
    return (
        <Btn
            className={`${style.btn} ${size? style['btn--' + size] : ''} ${addClass ? addClass : ''}`}
            link={link}
            action={action}
            type={type}
            disabled={disabled}
        >
        {children}
        </Btn>
    )
}

export const BtnOutline:FC<OuterBtnProps> = ({
        children,
        link,
        action = () => false,
        size,
        disabled = false,
        addClass
}) => {
    return (
        <Btn
            className={`${style['btn-outline']} ${size? style['btn--' + size] : ''} ${addClass ? addClass : ''}`}
            link={link}
            action={action}
            disabled={disabled}
        >
            {children}
        </Btn>
    )
}


export const BtnText:FC<OuterBtnProps> = ({
    children,
    link,
    action = () => false,
    size,
    disabled = false,
    addClass
}) => {
    return (
        <Btn
            className={`${style['btn-text']} ${size? style['btn--' + size] : ''} ${addClass ? addClass : ''}`}
            link={link}
            action={action}
            disabled={disabled}
        >
            {children}
        </Btn>
    )
}
