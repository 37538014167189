import React, {useEffect} from 'react'
import useCoreBitrix from '../../hooks/coreBitrix.hook'

const BitrixLoader = () => {
	const fetchCore = useCoreBitrix()
	useEffect(() => {
		fetchCore()
	}, [fetchCore])
	return (
		<></>
	)
}

export default BitrixLoader