import axios from 'axios'
import {request} from './index'
import {TCartFormatted} from '../types/TCart'

// получаем список товаров в корзине
export const fetchCartProducts = async () => {
    try {
        const response = await request('GET', '/api/basket/table')
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// добавляем в корзину
export const addCartProduct = async (offerId: string) => {
    try {
        const response = await request('GET', '/api/basket/add', {offerId})
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

// Устанавливаем кол-во в корзине
export const setCartProduct = async (offerId: number, quantity:number) => {
    try {
        const response = await request('GET', '/api/basket/set', {offerId, quantity})
        return response.data
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const syncBasket = async (data: TCartFormatted[]) => {
    console.log(data)
    const bodyFormData = new FormData()
    if (data.length) {
        //FIXME Убрать эту шнягу, сделать красивыщно
        data.forEach((item: any, index: number) => {
            bodyFormData.append(`items[${index}][uid]`, item.uid)
            bodyFormData.append(`items[${index}][quantity]`, item.value)
        })
    } else {
        bodyFormData.append(`items`, '')
    }
    try {
        const response = await axios.post('/api/basket/sync', bodyFormData)
        return response.data.data
    } catch (e) {

    }
}

export const clearBasket = async () => {
    try {
        const response = await request('GET', '/api/basket/clear')
        return response.data
    } catch (err) {
        console.log(err)
    }
}