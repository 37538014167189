import React from 'react'
import TopBar from './TopBar/TopBar'
import BottomBar from './BottomBar/BottomBar'
import DevPanel from '../DevPanel/DevPanel'
import MobileMenu from './MobileMenu/MobileMenu'

import styles from './Header.module.scss'
export const StylesContext = React.createContext(styles)

const Header = () => {
    return (
        <StylesContext.Provider value={styles}>
            {process.env.REACT_APP_STAGE === 'development' && <DevPanel/>}
            <TopBar/>
            <BottomBar/>
            <MobileMenu/>
        </StylesContext.Provider>
    )
}

export default Header
