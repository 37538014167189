import axios from 'axios'

export const initDataOrder = async () => {
    try {
        const result = await axios.post('/api/order/init')
        return result.data.data
    } catch (e) {
        console.log(e)
    }
}

export const makeOrder = async (data : {paymentId: string, deliveryId: string, locationCode: string}) => {
    try {
        const result = await axios.post('/api/order/make', data)
        return result.data.data
    }
  catch (e) {
      console.log(e)
  }
}