import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import StoreContext from '../../../../../stores/StoreContext'
import {observer} from 'mobx-react'
import style from './UserPopover.module.scss'
import user from '../../../../../assets/img/user.svg'
import exit from '../../../../../assets/img/exit.svg'

const UserPopover = observer(() => {
	const stores = useContext(StoreContext)
	return (
		<div className={`${style.menu} ${style['menu__right']}`}>
			<>
				{stores &&
					<>
                        <Link
                            to='/personal/'
                            className={`${style['menu-item']} ${style['menu-item__grid']}`}>
                            <img src={user} alt="Профиль"/>
                            <div>Профиль</div>
                        </Link>
                        <div
                            onClick={() => stores.personalStore.logout()}
                            className={`${style['menu-item']} ${style['menu-item__grid']}`}>
                            <img src={exit} alt="Выход"/>
                            <div>Выход</div>
                        </div>
					</>
				}
			</>
		</div>
	)
})

export default UserPopover