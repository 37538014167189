import React from 'react'

type IconCalendarProps = {className?: string}

const IconCalendar = ({className} : IconCalendarProps) => {
    return (
        <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.625 9C5.8125 9 6 8.84375 6 8.625V7.375C6 7.1875 5.8125 7 5.625 7H4.375C4.15625 7 4 7.1875 4 7.375V8.625C4 8.84375 4.15625 9 4.375 9H5.625ZM9 8.625V7.375C9 7.1875 8.8125 7 8.625 7H7.375C7.15625 7 7 7.1875 7 7.375V8.625C7 8.84375 7.15625 9 7.375 9H8.625C8.8125 9 9 8.84375 9 8.625ZM12 8.625V7.375C12 7.1875 11.8125 7 11.625 7H10.375C10.1562 7 10 7.1875 10 7.375V8.625C10 8.84375 10.1562 9 10.375 9H11.625C11.8125 9 12 8.84375 12 8.625ZM9 11.625V10.375C9 10.1875 8.8125 10 8.625 10H7.375C7.15625 10 7 10.1875 7 10.375V11.625C7 11.8438 7.15625 12 7.375 12H8.625C8.8125 12 9 11.8438 9 11.625ZM6 11.625V10.375C6 10.1875 5.8125 10 5.625 10H4.375C4.15625 10 4 10.1875 4 10.375V11.625C4 11.8438 4.15625 12 4.375 12H5.625C5.8125 12 6 11.8438 6 11.625ZM12 11.625V10.375C12 10.1875 11.8125 10 11.625 10H10.375C10.1562 10 10 10.1875 10 10.375V11.625C10 11.8438 10.1562 12 10.375 12H11.625C11.8125 12 12 11.8438 12 11.625ZM15 3.5C15 2.6875 14.3125 2 13.5 2H12V0.375C12 0.1875 11.8125 0 11.625 0H10.375C10.1562 0 10 0.1875 10 0.375V2H6V0.375C6 0.1875 5.8125 0 5.625 0H4.375C4.15625 0 4 0.1875 4 0.375V2H2.5C1.65625 2 1 2.6875 1 3.5V14.5C1 15.3438 1.65625 16 2.5 16H13.5C14.3125 16 15 15.3438 15 14.5V3.5ZM13.5 14.3125C13.5 14.4375 13.4062 14.5 13.3125 14.5H2.6875C2.5625 14.5 2.5 14.4375 2.5 14.3125V5H13.5V14.3125Z"/>
        </svg>
    )
}

export default IconCalendar
