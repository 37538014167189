import React, {useContext, useEffect} from 'react'
import StoreContext from '../../stores/StoreContext'
import {observer} from 'mobx-react'

const CheckAuth = observer(() => {
	const stores = useContext(StoreContext)
	useEffect(() => {
		if (stores) {
			stores.personalStore.getUser()
		}
	}, [stores])
	return (
		<></>
	)
})

export default CheckAuth