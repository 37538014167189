import React, {FC} from 'react'
import {Portal} from '@material-ui/core'

type BackdropProps = {
	handleClick: (n: any) => void
	activeClass?: string
}

const Backdrop:FC<BackdropProps> = ({handleClick, activeClass}) => {
	const app: HTMLElement | null = document.getElementById('root')
	return (
		<Portal container={app}>
			<div className={`overlay ${activeClass ? activeClass : 'active'}`} onClick={() => handleClick(false)}/>
		</Portal>
	)
}

export default Backdrop