import {makeAutoObservable, runInAction} from 'mobx'


class HeaderStore {
	mobileMenuOpened = false

	constructor() {
		makeAutoObservable(this)
	}

	toggleMobileMenu = () => {
		runInAction(() => {
			this.mobileMenuOpened = !this.mobileMenuOpened
		})
	}
}

export default HeaderStore