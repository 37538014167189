import React from 'react'
import {Link} from 'react-router-dom'
import style from './Logo.module.scss'

const LogoLink = () => <Link to={'/'} className={style.logo__link}>Логотип</Link>

type LogoProps = {
    className: string,
    children?: React.ReactNode
}

const Logo = ({className, children} : LogoProps) => {
    return (
        <div className={`${style.logo} ${className}`}>
            <LogoLink/>
            {children}
        </div>
    )
}

export default Logo
