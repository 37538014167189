import React from 'react'

type IconLocationProps = {className?: string}

const IconLocation = ({className} : IconLocationProps) => {
    return (
        <svg className={className} width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.4062 0C15.25 0 15.0625 0.0625 14.875 0.125L1.875 6.125C0.375 6.8125 0.875 9.03125 2.46875 9.03125H7.96875V14.5312C7.96875 15.4688 8.75 16 9.5 16C10.0312 16 10.5938 15.75 10.875 15.125L16.875 2.125C17.3125 1.09375 16.4375 0 15.4062 0ZM9.46875 14.5312V7.53125H7.96875H2.5C2.5 7.53125 2.46875 7.5 2.5 7.46875L15.4375 1.53125L15.4688 1.5625L9.46875 14.5312Z"/>
        </svg>
    )
}

export default IconLocation
