import {makeAutoObservable, reaction, runInAction} from 'mobx'
import validator from 'validator'
import debounce from '@material-ui/core/utils/debounce'
import {fetchUsers} from '../api'

class ProfileStore {
    profileType = 'personal'
    formDisabled = true
    deleteModalOpened = false
    deleteUid = ''
    editModalOpened = false
    editUid = ''
    email = 'email@email.com'
    profileMenu = [
        {
            title: 'Данные организации',
            url: 'organization',
            active: true
        },
        {
            title: 'Структура организации',
            url: 'organization-structure',
            active: false
        },
        {
            title: 'Настройки аккаунта',
            url: 'settings',
            active: false
        }
    ]

    profileData = [
        {
            label: 'Договор',
            value: '0460663 от 23.12.2021'
        },
        {
            label: 'Контактное лицо',
            value: 'Алексеев Дмитрий, +7 (653) 755-56-55, email@mail.com'
        }
    ]

    dataTable = {
        loading: true
    }

    form: any = {
        name: {
            label: 'Наименование',
            value: 'Название',
            type: 'text',
            disabled: false,
            error: false
        },
        inn: {
            label: 'ИНН организации',
            value: 'ИНН',
            disabled: true,
            error: false
        },
        kpp: {
            label: 'КПП',
            value: 'КПП',
            disabled: false,
            error: false
        },
        email: {
            label: 'E-mail',
            value: 'Email',
            disabled: false,
            error: false
        },
        phone: {
            label: 'Телефон',
            value: '880323112312',
            disabled: false,
            error: false
        },
        bik: {
            label: 'БИК банка',
            value: '123213123',
            disabled: false,
            error: false
        },
        bank: {
            label: 'Банк',
            value: 'банк',
            disabled: true,
            error: false
        },
        corrAcc: {
            label: 'Корр. счёт',
            value: '13213123',
            disabled: true,
            error: false
        },
        checkAcc: {
            label: 'Расчетный счёт',
            value: '12312231321',
            disabled: false,
            error: false
        }
    }

    constructor() {
        makeAutoObservable(this)
        let formKeys = Object.keys(this.form)
        for (let i = 0; i < formKeys.length; i++) {
            reaction(() => this.form[formKeys[i]].value,
                debounce(() => {
                    console.log('validate ' + this.form[formKeys[i]].value)
                    this.validate()
                }, 500))
        }
    }

    setActiveMenuItem = (url: string) => {
        let urlLastLevel = url.split('/').pop()
        const index = this.profileMenu.findIndex(item => item.url === urlLastLevel)
        if (index >= 0) {
            runInAction(() => {
                this.profileMenu.map(item => item.active = false)
                this.profileMenu[index].active = true
            })
        }
    }

    getUsers = async () => {
        await runInAction(() => {
            this.dataTable.loading = true
            fetchUsers()
                .then(res => {
                    this.dataTable = res
                    this.dataTable.loading = false
                })
                .catch(err => console.log(err))
        })
    }

    deleteUser = (uid: string) => {
        runInAction(() => {
            this.deleteModalOpened = true
            this.deleteUid = uid
        })
    }

    editUser = (uid: string) => {
        runInAction(() => {
            this.editModalOpened = true
            this.editUid = uid
        })
    }

    validate = () => {
        let formKeys = Object.keys(this.form)
        let errorsCount = 0
        for (let i = 0; i < formKeys.length; i++) {
            runInAction(() => {
                if (formKeys[i] === 'email') {
                    if (!validator.isEmail(this.form[formKeys[i]].value)) {
                        this.form[formKeys[i]].error = true
                        errorsCount++
                    }
                    else this.form[formKeys[i]].error = false

                }
                if (formKeys[i] === 'phone') {
                    if (!validator.isMobilePhone(this.form[formKeys[i]].value, 'ru-RU')) {
                        this.form[formKeys[i]].error = true
                        errorsCount++
                    }
                    else this.form[formKeys[i]].error = false
                }
            })
        }
        console.log('errors: ' + errorsCount)

        this.formDisabled = errorsCount > 0
    }
}

export default ProfileStore