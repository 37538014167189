import React, {FC} from 'react'
import styles from './ChooseLocationModal.module.scss'
import CityItem from './CityItem'

type CityListProps = {
	cities: {
		value: string,
		unrestricted_value: string,
		data: {
			bx_id: string,
			bx_code: string,
			postal_code: string,
			price: 'Y' | 'N'
		}
	}[]
}

const CityList:FC<CityListProps> = ({cities}) => {
	return (
		<div className={styles.cityes__list}>
			{
				cities.map((item) =>
					<CityItem city={item} key={item.data.bx_id}/>
				)
			}
		</div>
	)
}

export default CityList