import React, {FC} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import ISelectMenu from '../../types/ISelectMenu'

const SelectMenu: FC<ISelectMenu> = ({
     size = 'medium',
     minWidth = '0',
     active,
     error = '',
     options,
     handle,
     outline = false,
     dark,
     eventReturn,
     disabled = false,
     disabledOptions
}) => {

    const useStyles = makeStyles({
        formControl: {
            margin: 0,
            minWidth: minWidth,
            maxWidth: '100%',
            opacity: disabled ? .5 : 1,
            '& .MuiOutlinedInput-notchedOutline': {
                border: error ? '1px solid #ff2b2b' : (outline ? '1px' +
                    ' solid' +
                    ' rgba(0, 0, 0, 0.23)' : 'none')
            },
            '& .Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.35)',
                    borderWidth: 1
                }
            },
            '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.35)'
                }
            }
        },
        selectMenu: {
            background: dark ? '#313640' : '#fff',
            fontSize: size === 'small' ? 14 : 16,
            padding: size === 'large' ? '12.5px 12px' : '6.5px 12px',
            paddingLeft: outline || dark ? '12px' : 0,
            paddingRight: outline ? '36px !important' : '26px !important',
            color: dark ? '#fff' : 'rgba(0, 0, 0, 0.87)',
            '&::after': {
                right: outline || dark ? 10 : 0,
                top: size === 'large' ? 13 : 8
            },
            '&:focus': {
                background: dark ? '#313640' : '#fff',
                color: dark ? '#fff' : 'rgba(0, 0, 0, 0.87)'
            },
            '&.Mui-disabled': {
                background: '#f8f8f8',
                color: '#7E8489',
                cursor: 'not-allowed'
            }
        },
        listFontSize: {
            '& .MuiListItem-root': {
                fontSize: size === 'large' ? 16 : 14,
                padding: size === 'large' ? '10px 12px' : '4px 12px'
            }
        },
        listPaper: {
            border: dark ? 'none' : '1px solid #C4C4C4',
            background: dark ? '#313640' : '#fff',
            color: dark ? '#fff' : 'rgba(0, 0, 0, 0.87)',
            '& .MuiListItem-root:hover': {
                backgroundColor: dark ? '#3c424e' : '#F8F8F8'
            },
            '& .Mui-selected': {
                color: dark ? '#fff' : '#242A35',
                backgroundColor: dark ? '#3c424e' : '#F8F8F8'
            }
        }
    })
    const classes = useStyles()
    const handleChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        if (eventReturn) {
            return handle(event)
        }
        handle(event.target.value)
    }
    return (
        <FormControl className={classes.formControl}>
            <Select
                variant="outlined"
                classes={{selectMenu: classes.selectMenu}}
                value={active}
                onChange={handleChange}
                disabled={disabled}
                MenuProps={{
                    classes: {
                        list: classes.listFontSize,
                        paper: classes.listPaper
                    },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        horizontal: 'left',
                        vertical: 'bottom'
                    }
                }}
            >
                {
                    options.map((value, index) =>
                        <MenuItem
                            key={index}
                            value={index}
                            disabled={disabledOptions ? index !== 0 && disabledOptions.findIndex((item) => item === index) >= 0 : false}>
                            {value}
                        </MenuItem>)
                }
            </Select>
        </FormControl>

    )
}

export default SelectMenu
