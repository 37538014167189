import {useContext} from 'react'
import StoreContext from '../stores/StoreContext'
import {autorun, reaction, runInAction, toJS} from 'mobx'
import {fetchCoreBitrix} from '../api/main'

declare global {
    interface Window {
        BX: any;
    }
}

type objType = {
    type: string
    content: string
}

const useCoreBitrix = () => {
    const stores = useContext(StoreContext)
    const fetchCore = () => {
        if (stores) {
            fetchCoreBitrix()
                .then(r => {
                    console.log('грузим битрикс')
                    runInAction(() => {
                        r.forEach((item: any) => {
                            stores.personalStore.bitrixScriptsLoading.push({loaded: false})
                        })
                    })
                    const loading = autorun(() => {
                        let foundNotLoaded = -1
                        stores.personalStore.bitrixScriptsLoading.forEach((item: any, index: number) => {
                            if (!stores.personalStore.bitrixScriptsLoading[index].loaded) {
                                foundNotLoaded = index
                            }
                        })
                        if (foundNotLoaded === -1) {
                            stores.personalStore.bitrixAllLoaded = true
                            console.log('битрикс загрузился')
                        }
                    })
                    createScript(r, 0)
                })
        }
    }
    const createScript = (obj: objType[], index: number) => {
        if (!obj[index]) {
            return
        }
        const head = document.getElementsByTagName('head')[0]
        const script = document.createElement('script')
        script.type = 'text/javascript'
        if (obj[index].type === 'src') {
            script.src = obj[index].content
            script.onload = () => {
                if (stores) {
                    runInAction(() => {
                        if (stores.personalStore.bitrixScriptsLoading[index]) {
                            stores.personalStore.bitrixScriptsLoading[index].loaded = true
                        }
                    })
                }
                createScript(obj, index + 1)
            }
            head.appendChild(script)
        } else {
            script.text = obj[index].content
            head.appendChild(script)
            if (stores) {
                runInAction(() => {
                    if (stores.personalStore.bitrixScriptsLoading[index]) {
                        stores.personalStore.bitrixScriptsLoading[index].loaded = true
                    }
                })
            }
            createScript(obj, index + 1)
        }
    }

    return fetchCore
}

export default useCoreBitrix