import React, {useContext} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import Header from '../Header/Header'
import Routes from '../../Routes'
import Footer from '../Footer/Footer'
import Preloader from '../Preloader/Preloader'
import StoreContext from '../../stores/StoreContext'
import {observer} from 'mobx-react'

const AppContent = observer(() => {
	const stores = useContext(StoreContext)
	return (
		<>
			{(stores && stores.personalStore.bitrixAllLoaded) ?
				<Router>
					<Header/>
					<Routes/>
					<Footer/>
				</Router>
				: <Preloader/>}
		</>
	)
})

export default AppContent