import {makeAutoObservable, runInAction} from 'mobx'
import {fetchSellerReturnOrders} from '../api'

class SellerReturnOrdersStore {

	dataTable = {
		settings: {},
		rows: [],
		loading: true
	}

	pagination = {
		page: 0,
		limit: 30,
		size: 400
	}

	constructor() {
		makeAutoObservable(this)
	}

	get paginationSelect() {
		const options = ['10', '20', '30']
		const perPage = options.findIndex(item => parseInt(item) === this.pagination.limit)
		return {
			perPage: perPage,
			options: ['10', '20', '30']
		}
	}

	getReturns = async () => {
		runInAction(() => {
			this.dataTable.loading = true
			fetchSellerReturnOrders()
				.then(res => {
					this.dataTable = res
					this.dataTable.loading = false
				})
				.catch(err => console.log(err))
		})
	}
}

export default SellerReturnOrdersStore