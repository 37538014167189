import {makeAutoObservable, runInAction} from 'mobx'

import {fetchDocuments} from '../api'

class DocumentsStore {
    filterOpen = false
    dataTable = {
        settings: {
            desktop: [
                'date',
                'documentNumber',
                'paymentMethod',
                'summ',
                'paymentDate',
                'backMoney',
                'payment',
            ],
            mobile: [
                'date',
                'documentNumber',
                'paymentMethod',
                'summ',
                'paymentDate',
                'backMoney',
                'payment',
            ]
        },
        rows: [
            {
                uid: '123',
                date: {
                    date: {
                        timestamp: '2021-08-30T07:23:33+0000'
                    }
                },
                documentNumber: {
                    caption: {
                        text: 'Cф чето там',
                        style: 'gray'
                    },
                    link: {
                        text: '123123',
                        url: '#'
                    }
                },
                paymentMethod: '',
                summ: 2785800.45,
                paymentDate: {
                    date: {
                        timestamp: '2021-08-30T07:23:33+0000'
                    }
                },
                backMoney: 2785800.45,
                payment: 2785800.45,
            },
            {
                uid: '123',
                date: {
                    date: {
                        timestamp: '2021-08-30T07:23:33+0000'
                    }
                },
                documentNumber: {
                    label: 'Cф чето там',
                    link: {
                        text: '123123',
                        url: '#'
                    }
                },
                paymentMethod: '',
                summ: 2785800.45,
                paymentDate: {
                    date: {
                        timestamp: '2021-08-30T07:23:33+0000'
                    }
                },
                backMoney: 2785800.45,
                payment: 2785800.45,
            },
            {
                uid: '123',
                date: {
                    date: {
                        timestamp: '2021-08-30T07:23:33+0000'
                    }
                },
                documentNumber: {
                    label: 'Cф чето там',
                    link: {
                        text: '123123',
                        url: '#'
                    }
                },
                paymentMethod: '',
                summ: 2785800.45,
                paymentDate: {
                    date: {
                        timestamp: '2021-08-30T07:23:33+0000'
                    }
                },
                backMoney: 2785800.45,
                payment: 2785800.45,
            }
        ],
        loading: true
    }
    filters = {
        for: {
            active: 0,
            options: [
                {
                    label: 'Документ',
                    link: '123'
                },
                {
                    label: 'Деталь',
                    link: '123'
                }
            ]
        },
        dateRange: {
            currentDate: new Date(),
            start: new Date(),
            end: new Date(),
        },
        contract: {
            active: 2,
            options: ['ТДСТ/МСК/3749/ОО, от 20.12.2021', 'ТДСТ/МСК/3749/ОО, от 20.12.2021', 'ТДСТ/МСК/3749/ОО, от 20.12.2021']
        },
        checkboxes: [
            {
                label: 'Покупка',
                checked: false,
                name: 'buy'
            },
            {
                label: 'Возврат',
                checked: true,
                name: 'return'
            },
            {
                label: 'Поступление ДС',
                checked: false,
                name: 'ds'
            }
        ],
        payment: {
            active: 1,
            options: ['Любой', 'Наличный', 'Безналичный']
        },

        found: 12
    }

    pagination = {
        page: 0,
        limit: 30,
        size: 400
    }

    get paginationSelect() {
        const options = ['10', '20', '30']
        const perPage = options.findIndex(item => parseInt(item) === this.pagination.limit)
        return {
            perPage: perPage,
            options: ['10', '20', '30']
        }
    }

    constructor() {
        makeAutoObservable(this)
    }

    getDocuments = async () => {
        await runInAction(() => {
            this.dataTable.loading = true
             fetchDocuments()
                .then(res => {
                    this.dataTable = res
                    this.dataTable.loading = false
                })
                .catch(err => console.log(err))
        })
    }

    toggleFilter = () => {
        this.filterOpen = !this.filterOpen
    }

    setDateRangeStart = (start: Date) => {
        this.filters.dateRange.start = start
    }

    setDateRangeDate = (date: Date) => {
        this.filters.dateRange.currentDate = date
    }

    setDateRangeEnd = (end: Date) => {
        this.filters.dateRange.end = end
    }

    toggleCheckbox = (name: string, checked: boolean) => {
        const index = this.filters.checkboxes.findIndex(el => el.name === name)
        this.filters.checkboxes[index].checked = checked
    }

    toggleFilterTab = (index: number) => {
        this.filters.for.active = index
    }
}

export default DocumentsStore
