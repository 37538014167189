import React from 'react'

type IconExitProps = {className?: string}

const IconExit = ({className} : IconExitProps) => {
	return (
		<svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.5 3.5V5.125H5.5C4.65625 5.125 4 5.8125 4 6.625V9.40625C4 10.2188 4.65625 10.9062 5.5 10.9062H8.5V12.5C8.5 13.8438 10.0938 14.5 11.0312 13.5625L15.5312 9.0625C16.125 8.5 16.125 7.53125 15.5312 6.9375L11.0312 2.4375C10.0938 1.5 8.5 2.1875 8.5 3.5ZM14.5 8L10 12.5V9.40625H5.5V6.625H10V3.5L14.5 8ZM3 2C1.34375 2 0 3.34375 0 5V11C0 12.6562 1.34375 14 3 14H5.625C5.8125 14 6 13.8438 6 13.625V12.875C6 12.6875 5.8125 12.5 5.625 12.5H3C2.15625 12.5 1.5 11.8438 1.5 11V5C1.5 4.1875 2.15625 3.5 3 3.5H5.625C5.8125 3.5 6 3.34375 6 3.125V2.375C6 2.1875 5.8125 2 5.625 2H3Z" fill="white"/>
		</svg>
	)
}

export default IconExit