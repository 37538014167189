import {makeAutoObservable, toJS} from 'mobx'
import {fetchStatic, setLocation} from '../api/main'

class StaticStore {
    footerLinks = [
        {
            url: '/footer1',
            text: 'О компании'
        },
        {
            url: '/footer1',
            text: 'Оплата и доставка'
        },
        {
            url: '/footer1',
            text: 'Поставщикам'
        },
        {
            url: '/footer1',
            text: 'Возврат'
        },
        {
            url: '/footer1',
            text: 'Контакты'
        },
        {
            url: '/footer1',
            text: 'Пользовательское соглашение'
        },
        {
            url: '/footer1',
            text: 'Политика конфеденциальности'
        },
    ]
    bitrixAllLoaded = false
    googleMapApiKey = ''
    yandexMapApiKey = ''
    googleRCKey = ''
    HCKey = ''
    geo: {
        value: string
        data: {
            bx_id: string
            bx_code: string
        }
    } = {
        value: 'Москва',
        data: {
            bx_id: '107',
            bx_code: '0000073738'
        }
    }
    favLocation: {
        value: string
        unrestricted_value: string
        data: {
            bx_id: string
            bx_code: string
            postal_code: string
            price: 'Y' | 'N'
        }
    }[] = []

    constructor() {
        makeAutoObservable(this)
    }

    getStatic = async () => {
        fetchStatic()
            .then(r => {
                this.googleMapApiKey = r.googleMapApiKey
                this.yandexMapApiKey = r.yandexMapApiKey
                this.googleRCKey = r.googleRCKey
                this.HCKey = r.HCKey
                this.geo = r.geo
                this.favLocation = r.favLocation
                this.bitrixAllLoaded = true
            })
            .catch(e => console.log(e))
    }

    setLocation = async (bx_id: string) => {
        setLocation(bx_id)
            .then(r => {
                this.geo = r.data
            })
            .catch(e => console.log(e))
    }

}

export default StaticStore
