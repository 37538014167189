import {makeAutoObservable, runInAction} from 'mobx'
import {fetchSellerOrder, fetchSellerOrderConfirmed, fetchSellerOrderPartlyConfirmed} from '../api'

type dataRow = {
	uid: string,
	brand: {
		label: string,
		link: {
			text: string,
			url: string
		}
	},
	product: {
		label: string,
		add: {
			tooltip: string
		}
	},
	cnt: number,
	changeCntInput: {
		input: {
			text: string,
			disabled: boolean,
			error: string
		}
	},
	price: number,
	summ: number
}

type orderStatus = 'confirm' | 'partly-confirmed' | 'confirmed'

class SellerOrderStore {

	status = 'confirm'

	generalInfo = [
		{
			label: 'Дата создания',
			info: {
				text: '18.05.2021 16:40:18'
			}
		},
		{
			label: 'Крайняя дата отгрузки',
			info: {
				status: 'red',
				text: '18.05.2021 — 18.05.2021'
			}
		},
		{
			label: 'Сумма по заказу',
			info: {
				text: '10 000 руб.'
			}
		},
		{
			label: 'Статус',
			info: {
				status: 'yellow',
				text: 'Требует доставки'
			}
		}
	]

	dataTable = {
		loading: true,
		rows: [] as dataRow[]
	}

	constructor() {
		makeAutoObservable(this)
	}

	getOrder = async () => {
		await runInAction(() => {
			this.dataTable.loading = true
			this.status = 'confirm'
			fetchSellerOrder()
				.then(r => {
					this.dataTable = r
					this.dataTable.loading = false
				})
				.catch(err => console.log(err))
		})
	}

	getConfirmedOrder = async () => {
		await runInAction(() => {
			this.status = 'confirmed'
			this.dataTable.loading = true
			fetchSellerOrderConfirmed()
				.then(r => {
					this.dataTable = r
					this.dataTable.loading = false
				})
				.catch(err => console.log(err))
		})
	}

	getPartlyConfirmedOrder = async () => {
		await runInAction(() => {
			this.status = 'partly-confirmed'
			this.dataTable.loading = true
			fetchSellerOrderPartlyConfirmed()
				.then(r => {
					this.dataTable = r
					this.dataTable.loading = false
				})
				.catch(err => console.log(err))
		})
	}

	handleInput = (uid: string, input: string) => {
		const inputRowIndex = this.dataTable.rows.findIndex(el => el.uid === uid)
		this.dataTable.rows[inputRowIndex].changeCntInput.input.text = input
	}
}

export default SellerOrderStore