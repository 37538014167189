import {createTheme} from '@material-ui/core'

const muiTheme = createTheme({
    overrides: {
        // всплывающая фигня
        MuiPopover: {
            paper: {
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                borderRadius: '0 0 4px 4px',
                border: '2px solid #C4C4C4',
                borderTop: 0
            }
        },
        // список
        MuiList: {
            root: {
                maxHeight: 560,
                overflow: 'auto',
                padding: '0 !important',
                '& .MuiListItem-root': {
                    fontFamily: '"FuturaPT", -apple-system, BlinkMacSystemFont, Arial, sans-serif',
                    fontSize: 16,
                    color: '#7E8489',
                    padding: '4px 12px'
                },
                '& .Mui-selected': {
                    color: '#242A35',
                    backgroundColor: '#F8F8F8'
                }
            }
        },
        // селект меню
        MuiSelect: {
            root: {
                margin: 0,
                fontFamily: '"FuturaPT", -apple-system, BlinkMacSystemFont, Arial, sans-serif'
            },
            selectMenu: {
                fontSize: '16px',
                padding: '6.5px 12px',
                paddingRight: '36px !important',
                background: '#fff',
                '&::after': {
                    content: '""',
                    display: 'inline-block',
                    background: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDExIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjcxODc1IDUuODc1QzUuODc1IDYuMDMxMjUgNi4wOTM3NSA2LjAzMTI1IDYuMjUgNS44NzVMMTAuODc1IDEuMjgxMjVDMTEuMDMxMiAxLjE1NjI1IDExLjAzMTIgMC45MDYyNSAxMC44NzUgMC43NUwxMC4yNSAwLjE1NjI1QzEwLjEyNSAwIDkuODc1IDAgOS43MTg3NSAwLjE1NjI1TDYgMy44NDM3NUwyLjI1IDAuMTU2MjVDMi4wOTM3NSAwIDEuODc1IDAgMS43MTg3NSAwLjE1NjI1TDEuMDkzNzUgMC43NUMwLjkzNzUgMC45MDYyNSAwLjkzNzUgMS4xNTYyNSAxLjA5Mzc1IDEuMjgxMjVMNS43MTg3NSA1Ljg3NVoiIGZpbGw9IiM5Njk2OTYiLz4KPC9zdmc+Cg==) no-repeat center',
                    transition: 'all .3s ease 0s',
                    width: 16,
                    height: 16,
                    position: 'absolute',
                    right: 10,
                    top: 8
                },
                '&:focus': {
                    background: '#fff',
                    outline: 'none',
                    borderRadius: 4,
                    overflow: 'hidden',
                }
            },
            icon: {
                display: 'none'
            },
        },
        // ползунок
        MuiSlider: {
            root: {
                color: '#EE5E20',
                paddingBottom: 0,
            },
            thumb: {
                '&$active': {
                    boxShadow: '0px 0px 0px 8px rgba(238, 94, 32, 0.16)'
                },
                '&$active:focus': {
                    boxShadow: '0px 0px 0px 8px rgba(238, 94, 32, 0.16)'
                },
                '&:focus': {
                    boxShadow: '0px 0px 0px 0px rgba(238, 94, 32, 0.16)'
                },
                '&:hover': {
                    boxShadow: '0px 0px 0px 4px rgba(238, 94, 32, 0.16)'
                },

            },
            rail: {
                backgroundColor: '#c4c4c4',
                opacity: 1
            }
        }
    }
})

export default muiTheme
