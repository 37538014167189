import React, {useContext, useLayoutEffect, useState} from 'react'
import Container from '../../../layouts/Container/Container'

import Search from './components/Search/Search'
import ExtraSearchLinks from './components/ExtraSearchLinks'
import Company from './components/Company/Company'

import {StylesContext} from '../Header'
import {useScreenWidth} from '../../../hooks/screenWidth.hook'

const BottomBar = () => {
    const styles = useContext(StylesContext)
    const [scrollPrev, setScrollPrev] = useState(0)
    const [scrolled, setScrolled] = useState(0)
    const [hidden, setHidden] = useState(false)
    const screenWidth = useScreenWidth()

    useLayoutEffect(() => {
        function toggleBarOnScroll() {
			if (screenWidth <= 990) {
				setScrolled(window.scrollY)
				if (scrolled > 100 && scrolled > scrollPrev) {
					setHidden(true)
				}
				else {
					setHidden(false)
				}
				setScrollPrev(scrolled)
			}
        }

        function showBarOnDesktop() {
			if (screenWidth >= 990) {
				setHidden(false)
			}
        }

		window.addEventListener('scroll', toggleBarOnScroll)
		window.addEventListener('resize', showBarOnDesktop)
		return () => {
			window.removeEventListener('scroll', toggleBarOnScroll)
            window.removeEventListener('resize', showBarOnDesktop)
		}
    })
    return (
        <>
        <div className={`${styles.headerBottom} ${styles['show-company']} ${hidden ? styles.hide : ''}`}>
            <Container className={styles['headerBottom__container']}>
                <div className={styles['headerBottom--flex']}>
                    <Search/>
                    <ExtraSearchLinks/>
                </div>
            </Container>
            <Company/>
        </div>
    </>
    )
}

export default BottomBar
