import {makeAutoObservable, runInAction} from 'mobx'
import {fetchUploadedDoc, fetchUploadedDocErrors} from '../api'

type uploadStatusType = 'error' | 'success' | undefined

class SellerUploadDocumentStore {

	// uploadStatus = 'success'
	// uploadedFileName = 'file.xls'

	uploadStatus: uploadStatusType = 'success'
	uploadedFileName = undefined
	generalInfo = [
		{
			label: 'Контрагент',
			info: {
				text: 'Название контрагента'
			}
		},
		{
			label: 'Номер',
			info: {
				text: '65387563756'
			}
		},
		{
			label: 'Дата',
			info: {
				text: '12.12.2021'
			}
		},
		{
			label: 'УПД',
			info: {
				text: '65387563756.xls',
				url: '#'
			}
		},
		{
			label: 'Сумма УПД, руб.',
			info: {
				text: '2 785 8 00.45'
			}
		}
	]

	dataTable = {
		settings: {},
		rows: [],
		loading: true
	}

	constructor() {
		makeAutoObservable(this)
	}

	getUploadedDoc = async () => {
		runInAction(() => {
			this.dataTable.loading = true
			fetchUploadedDoc()
				.then(r => {
					this.dataTable = r.data
					this.dataTable.loading = false
				})
				.catch(err => console.log(err))
		})
	}

	getUploadedDocErrors = async () => {
		runInAction(() => {
			this.dataTable.loading = true
			fetchUploadedDocErrors()
				.then(r => {
					this.dataTable = r.data
					this.dataTable.loading = false
				})
				.catch(err => console.log(err))
		})
	}

}

export default SellerUploadDocumentStore