import {makeAutoObservable, runInAction} from 'mobx'
import {fetchDocuments} from '../api'

class SellerDocumentsStore {
	filterOpen = false
	dataTable = {
		settings: {},
		rows: [],
		loading: true
	}
	filters = {
		for: {
			active: 0,
			options: [
				{
					label: 'Документ',
					link: '123'
				},
				{
					label: 'Деталь',
					link: '123'
				}
			]
		},
		dateRange: {
			currentDate: new Date(),
			start: new Date(),
			end: new Date(),
		},
		checkboxes: [
			{
				label: 'Покупка',
				checked: false,
				name: 'buy'
			},
			{
				label: 'Возврат',
				checked: true,
				name: 'return'
			},
			{
				label: 'Поступление ДС',
				checked: false,
				name: 'ds'
			}
		],

		found: 12
	}

	pagination = {
		page: 0,
		limit: 30,
		size: 400
	}

	get paginationSelect() {
		const options = ['10', '20', '30']
		const perPage = options.findIndex(item => parseInt(item) === this.pagination.limit)
		return {
			perPage: perPage,
			options: ['10', '20', '30']
		}
	}

	constructor() {
		makeAutoObservable(this)
	}

	getDocuments = async () => {
		await runInAction(() => {
			this.dataTable.loading = true
			fetchDocuments()
				.then(res => {
					this.dataTable = res
					this.dataTable.loading = false
				})
				.catch(err => console.log(err))
		})
	}

	toggleFilter = () => {
		this.filterOpen = !this.filterOpen
	}

	setDateRangeStart = (start: Date) => {
		this.filters.dateRange.start = start
	}

	setDateRangeDate = (date: Date) => {
		this.filters.dateRange.currentDate = date
	}

	setDateRangeEnd = (end: Date) => {
		this.filters.dateRange.end = end
	}

	toggleCheckbox = (name: string, checked: boolean) => {
		const index = this.filters.checkboxes.findIndex(el => el.name === name)
		this.filters.checkboxes[index].checked = checked
	}

	toggleFilterTab = (index: number) => {
		this.filters.for.active = index
	}
}

export default SellerDocumentsStore