import React from 'react'
import style from './Preloader.module.scss'

type PreloaderProps = {
    fixed?: boolean
}

const Preloader = ({fixed} : PreloaderProps) => {
    return (
        <div className={`${style.wrapper} ${fixed ? style.fixed : ''}`} onMouseOver={() => false} onClick={() => false}>
            <svg className={style.loader} width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 19.2834C3 10.2903 10.3873 3 19.5 3C28.6127 3 36 10.2903 36 19.2834C36 23.8838 34.0668 28.0387 30.9583 31" stroke="#EE5E20" strokeWidth="5" strokeLinecap="round"/>
            </svg>
        </div>
    )
}

export default Preloader
