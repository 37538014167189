import React, {FC} from 'react'
import style from './Container.module.scss'

type ContainerProps = {
    addStyle?: object
    className?: string
}

// memo чтобы компонент не обновлялся тк нам это не нужно
const Container:FC<ContainerProps> = React.memo(({addStyle, className, children}) => {
    return (
        <div className={`${style.container} ${className ? className : ''}`} style={addStyle}>
            {children}
        </div>
    )
})

export default Container
