import {makeAutoObservable, runInAction} from 'mobx'
import {ReactElement} from 'react'
import IPushNotification from '../types/IPushNotification'

// type Notification = ReactElement

class NotificationsStore {
	notifications = [] as IPushNotification[]

	constructor() {
		makeAutoObservable(this)
	}

	pushNotification = (n: IPushNotification) => {
		runInAction(() => {
			this.notifications.push(n)
		})
	}

	shiftNotification = () => {
		runInAction(() => {
			this.notifications.shift()
		})
	}

	deleteNotification = (id: number) => {
		runInAction(() => {
			this.notifications.splice(id, 1)
		})
	}
}

export default NotificationsStore