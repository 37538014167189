import React from 'react'
import style from './Badge.module.scss'

type BadgeProps = {
    number: number | string,
    offset?: object,
    light?: boolean
}

const Badge = ({number, offset, light} : BadgeProps) => {
    return (
        <div className={`${style.notice} ${light ? style.notice__button : ''}`} style={offset}>{number}</div>
    )
}

export default Badge
