import React from 'react'
import style from './PopoverMenu.module.scss'
import {Link} from 'react-router-dom'

type PopoverProps = {
    data: {
        title: string,
        url: string,
        img?: string
    }[],
    position?: string
}

const PopoverMenu = ({data, position} : PopoverProps) => {
    return (
        <div className={`${style.menu} ${style['menu__'+position]}`}>
            {
                data.map((item, index) =>
                    <Link
                        to={item.url}
                        className={`${style['menu-item']} ${item.img ? style['menu-item__grid'] : null}`}
                        key={index}>
                        {item.img &&
                            <img src={item.img} alt={item.title}/>}
                        <div>{item.title}</div>
                    </Link>
                )
            }
        </div>
    )
}

export default PopoverMenu
