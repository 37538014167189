import React from 'react'

type IconCartProps = {className?: string}

const IconCart = ({className} : IconCartProps) => {
    return (
        <svg className={className} width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path d="M17.2188 2H4.5L4.21875 0.625C4.15625 0.28125 3.84375 0 3.5 0H0.375C0.15625 0 0 0.1875 0 0.375V1.125C0 1.34375 0.15625 1.5 0.375 1.5H2.875L5.03125 12.625C4.6875 13 4.5 13.4688 4.5 14C4.5 15.125 5.375 16 6.5 16C7.59375 16 8.5 15.125 8.5 14C8.5 13.7188 8.375 13.2812 8.21875 13H12.75C12.5938 13.2812 12.5 13.7188 12.5 14C12.5 15.125 13.375 16 14.5 16C15.5938 16 16.5 15.125 16.5 14C16.5 13.4375 16.25 12.9375 15.875 12.5625L15.9062 12.4375C16 11.9688 15.6562 11.5 15.1562 11.5H6.34375L6.0625 10H15.8125C16.1875 10 16.4688 9.78125 16.5625 9.4375L17.9688 2.9375C18.0625 2.46875 17.7188 2 17.2188 2ZM6.5 14.75C6.0625 14.75 5.75 14.4375 5.75 14C5.75 13.5938 6.0625 13.25 6.5 13.25C6.90625 13.25 7.25 13.5938 7.25 14C7.25 14.4375 6.90625 14.75 6.5 14.75ZM14.5 14.75C14.0625 14.75 13.75 14.4375 13.75 14C13.75 13.5938 14.0625 13.25 14.5 13.25C14.9062 13.25 15.25 13.5938 15.25 14C15.25 14.4375 14.9062 14.75 14.5 14.75ZM15.2188 8.5H5.75L4.78125 3.5H16.3125L15.2188 8.5Z"/>
        </svg>
    )
}

export default IconCart
