import React from 'react'
import {Link} from 'react-router-dom'

import style from './LinkItem.module.scss'

type LinkItemProps = {
    url: string,
    text: string
}

const LinkItem = ({url, text} : LinkItemProps) => {
    return (
        <Link to={url} className={style.footer__item}>{text}</Link>
    )
}

export default LinkItem
