import {makeAutoObservable} from 'mobx'

class CatalogStore {

	category = {
		title: 'Масла',
		catalog: [
			{
				name: 'Масла гидравлические',
				url: '#',
				title: 'Масла саб',
				catalog: [
					{
						title: 'Масла саб саб',
						catalog: [
							{
								title: 'Масла саб саб 2',
								catalog: [
									{
										name: 'Масла саб саб линк 1',
										url: '#'
									},
									{
										name: 'Масла саб саб линк 2',
										url: '#'
									}
								]
							},
							{
								name: 'Масла саб саб линк 1',
								url: '#'
							},
						]
					},
					{
						title: 'тайтл',
						catalog: [
							{
								name: 'Масла саб саб линк 1',
								url: '#'
							},
							{
								name: 'Масла саб саб линк 2',
								url: '#'
							}
						]
					}
				]
			},
			{
				name: 'Масла гидравлические',
				url: '#',
				title: 'Масла 2',
				catalog: [
					{
						title: 'Масла 2',
						catalog: [
							{
								name: 'Масла саб саб линк 1',
								url: '#'
							},
							{
								name: 'Масла саб саб линк 2',
								url: '#'
							}
						]
					}
				]
			},
			{
				name: 'Масла линк 3',
				url: '#'
			},
			{
				name: 'Масла линк 4',
				url: '#'
			}
		]
	}

	constructor() {
		makeAutoObservable(this)
	}
}

export default CatalogStore