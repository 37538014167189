import {makeAutoObservable, reaction, runInAction, toJS} from 'mobx'
import {fetchSellerReturnOrder} from '../api'

class SellerReturnOrderStore {
	sendBtnDisabled = true
	sent = false
	generalInfo = [
		{
			label: 'Дата создания',
			info: {
				text: '18.05.2021 16:40:18'
			}
		},
		{
			label: 'Статус возврата',
			info: {
				status: 'yellow',
				text: 'Требует подтверждения'
			}
		},
		{
			label: 'Сумма на возврат',
			info: {
				text: '10 000 руб.'
			}
		}
	]

	dataTable = {
		loading: true,
		rows: [] as any[]
	}

	constructor() {
		makeAutoObservable(this)
	}

	getReturnOrder = async () => {
		runInAction(() => {
			this.dataTable.loading = true
			fetchSellerReturnOrder()
				.then(res => {
					this.dataTable = res
					this.dataTable.loading = false
					for (let i = 0; i < this.dataTable.rows.length; i++) {
						reaction(
							() => this.dataTable.rows[i].returnDecision.returnDecision.decided,
							() => {
								runInAction(() => {
									this.sendBtnDisabled = this.dataTable.rows.findIndex(item => item.returnDecision.returnDecision.decided === false) !== -1;
								})
							}
						)
					}


				})
				.catch(err => console.log(err))
		})
	}

	acceptReturn = (uid: string) => {
		if (this.dataTable.rows.length > 0) {
			const index = this.dataTable.rows.findIndex((el: any) => el.uid === uid)
			if (this.dataTable.rows[index]) {
				console.log(toJS(this.dataTable.rows[index].returnDecision.returnDecision))
				runInAction(() => {
					this.dataTable.rows[index].returnDecision.returnDecision.decision = 'accept'
					this.dataTable.rows[index].returnDecision.returnDecision.decided = true
				})
			}
		}
	}

	denyReturn = (uid: string) => {
		if (this.dataTable.rows.length > 0) {
			const index = this.dataTable.rows.findIndex((el: any) => el.uid === uid)
			if (this.dataTable.rows[index]) {
				runInAction(() => {
					this.dataTable.rows[index].returnDecision.returnDecision.decision = 'deny'
					this.dataTable.rows[index].returnDecision.returnDecision.decided = true
				})
			}
		}
	}

	changeDecision = (uid: string) => {
		if (this.dataTable.rows.length > 0) {
			const index = this.dataTable.rows.findIndex((el: any) => el.uid === uid)
			if (this.dataTable.rows[index]) {
				runInAction(() => {
					if (this.dataTable.rows[index].returnDecision.returnDecision.decision === 'accept') {
						this.dataTable.rows[index].returnDecision.returnDecision.decision = 'deny'
					}
					else {
						this.dataTable.rows[index].returnDecision.returnDecision.decision = 'accept'
					}
				})
			}
		}
	}

	changeDenialDescription = (uid: string, descr: string) => {
		if (this.dataTable.rows.length > 0) {
			const index = this.dataTable.rows.findIndex((el: any) => el.uid === uid)
			if (this.dataTable.rows[index]) {
				runInAction(() => {
					this.dataTable.rows[index].returnDecision.returnDecision.description = descr
					console.log(toJS(this.dataTable.rows[index].returnDecision.returnDecision))
				})
			}
		}
	}

	send = () => {
		runInAction(() => {
			if (!this.sendBtnDisabled) {
				this.sent = true
			}
		})
	}
}

export default SellerReturnOrderStore