import React from 'react'

type IconSupportProps = {className?: string}

const IconSupport = ({className} : IconSupportProps) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="28" height="24" viewBox="0 0 28 24" fill="none">
            <path d="M24.5 7.5H20V3C20 1.35938 18.6406 0 17 0H3.5C1.8125 0 0.5 1.35938 0.5 3V10.5C0.5 12.1875 1.8125 13.5 3.5 13.5H5V15.9375C5 16.3125 5.23438 16.5 5.5625 16.5C5.65625 16.5 5.75 16.5 5.89062 16.4062L9.5 14.3906V18C9.5 19.6875 10.8125 21 12.5 21H17L22.0625 23.9062C22.2031 24 22.2969 24 22.4375 24C22.7188 24 23 23.8125 23 23.4375V21H24.5C26.1406 21 27.5 19.6875 27.5 18V10.5C27.5 8.85938 26.1406 7.5 24.5 7.5ZM3.5 12C2.65625 12 2 11.3438 2 10.5V3C2 2.20312 2.65625 1.5 3.5 1.5H17C17.7969 1.5 18.5 2.20312 18.5 3V10.5C18.5 11.3438 17.7969 12 17 12H10.5781L10.25 12.2344L6.5 14.3438V12H3.5ZM26 18C26 18.8438 25.2969 19.5 24.5 19.5H21.5V21.8438L17.7031 19.7344L17.375 19.5H12.5C11.6562 19.5 11 18.8438 11 18V13.5H17C18.6406 13.5 20 12.1875 20 10.5V9H24.5C25.2969 9 26 9.70312 26 10.5V18Z" fill="#313640"/>
        </svg>
    )
}

export default IconSupport
