import React, {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import IconLocation from '../../../../Svg/IconLocation'
import {StylesContext} from '../../../Header'
import StoreContext from '../../../../../stores/StoreContext'
import {observer} from 'mobx-react'
import ChooseLocationModal from './modals/ChooseLocationModal/ChooseLocationModal'
import {BtnOutline} from '../../../../Btn/Btn'
import {ClickAwayListener} from '@material-ui/core'
import useCookies from 'react-cookie/es6/useCookies'

const Location = observer(() => {
	const [showQuestion, setShowQuestion] = useState(false)
	const [openLocationModal, setOpenLocationModal] = useState(false)
	const [cookies, setCookies] = useCookies()
	const stores = useContext(StoreContext)
	const styles = useContext(StylesContext)
	useEffect(() => {
		if (!cookies.BITRIX_INT_ASKARON_GEO_LOCATION_ID) {
			setShowQuestion(true)
		}
	}, [cookies])
	const setCurrentLocationToCookie = () => {
		setCookies('BITRIX_INT_ASKARON_GEO_LOCATION_ID', stores?.staticStore.geo.data.bx_id, {path: '/'})
		setShowQuestion(false)
	}
	return (
		<div className={`${styles['header__basic-item']} ${styles.city}`}>
			<Link to={'#'} className={styles.city__link}>
				<IconLocation className={styles.city__icon}/>
				{stores &&
					<>
						<div
							className={styles.city__text}
							onClick={() => setShowQuestion(true)}>
							{stores.staticStore.geo.value}
						</div>
						{showQuestion &&
							<ClickAwayListener
								mouseEvent='onMouseDown'
								onClickAway={() => setShowQuestion(false)}>
                                <div className={styles.city__question}>
                                    <div className={styles['city__question-title']}>Ваш город {stores.staticStore.geo.value}?</div>
                                    <div className={styles['city__question-btn']}>
                                        <BtnOutline
                                            size='small'
                                            action={() => setOpenLocationModal(true)}>
                                            Нет
                                        </BtnOutline>
                                        <BtnOutline
                                            size='small'
                                            action={setCurrentLocationToCookie}>
                                            Да
                                        </BtnOutline>
                                    </div>
                                </div>
							</ClickAwayListener>}
						<ChooseLocationModal
							state={openLocationModal}
							setState={setOpenLocationModal}/>
					</>
				}
			</Link>
		</div>
	)
})

export default Location