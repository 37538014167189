import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import IconArrow from '../../../../Svg/IconArrow'
import PopoverMenu from '../../../../PopoverMenu/PopoverMenu'
// import StoreContext from '../../../../../stores/StoreContext'
import {StylesContext} from '../../../Header'
import {observer} from 'mobx-react'

const ReturnsMenu = observer(() => {
	const [menuReturnsShowing, setMenuReturnsShowing] = useState(false)
	// const stores = useContext(StoreContext)
	const styles = useContext(StylesContext)
	const menuReturns = [
		{
			title: 'Заявка на возврат',
			url: '/personal/returns/create'
		},
		{
			title: 'Возвраты',
			url: '/personal/returns'
		}
	]
	return (
		<div
			className={`${styles['header__addit-item']} ${styles.refunds}`}
			onMouseOver={(e) => setMenuReturnsShowing(true)}
			onMouseLeave={(e) => setMenuReturnsShowing(false)}
		>
			<Link to={'#'} className={styles.refunds__link} >
				<div className={styles['refunds__link-text']}>Возвраты</div>
				<IconArrow className={styles['refunds__link-icon']} />
			</Link>
			{menuReturnsShowing ? <PopoverMenu data={menuReturns} position={'center'}/> : null}
		</div>
	)
})

export default ReturnsMenu