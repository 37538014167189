import {makeAutoObservable, reaction, runInAction} from 'mobx'
import debounce from '@material-ui/core/utils/debounce'

class CreateReturnStore {
	deliveryTypeSelect = {
		active: 0,
		options: ['Самовывоз', 'Доставка', 'Транспортная компания']
	}
	checked : (any | null)[] = []
	imageLoading = false
	uploadedImages : any[] = []
	generalInfo = [
		{
			label: 'Договор №',
			info: {
				text: 'ТДСТ/МСК/3749/ОО от 20.12.2021'
			}
		},
		{
			label: 'Дата документа',
			info: {
				text: 'Доставка'
			}
		},
		{
			label: 'Покупаль',
			info: {
				text: 'ООО"СпецМаш"'
			}
		},
		{
			label: 'Грузополучатель',
			info: {
				text: 'ООО"СпецМаш"'
			}
		},
		{
			label: 'Способ получения',
			info: {
				text: 'Доставка'
			}
		},
		{
			label: 'Адрес доставки',
			info: {
				text: '125438, Москва, Лихачёвский 4-й пер., 4'
			}
		},
		{
			label: 'Контактное лицо',
			info: {
				text: 'Алексеев Дмитрий'
			}
		},
		{
			label: 'УПД/Товчек',
			info: {
				text: 'ДА0460663'
			}
		}
	]

	dataTable = {
		settings: {
			desktop: [
				'checkbox',
				'number',
				'brand',
				'product',
				'cnt',
				'price',
				'backcnt',
				'summ',
				'order',
				'gtd',
				'country',
				'backInput',
				'reason'
			],
			mobile: [
				'brand',
				'cnt',
				'price',
				'backcnt',
				'summ',
				'order',
				'gtd',
				'country',
				'backInput',
				'reason',
				'delete'
			]
		},
		rows: [
			{
				uid: '123',
				checkbox: {
					checkbox: {
						checked: false,
						disabled: false
					}
				},
				number: 1,
				brand: {
					label: 'BREMHOF',
					link: {
						text: 'HY9371',
						url: '#'
					}
				},
				product: {
					label: 'Фильтр гидравлический SF-FILTER',
					add:{
						tooltip: 'Фильтр гидравлический SF-FILTER'
					}
				},
				cnt: 16,
				price: 200.45,
				backcnt: 16,
				summ: 20000.45,
				order: {
					timestamp: '2021-08-30T07:23:33+0000',
					link: {
						text: '364259547',
						url: '#'
					}
				},
				gtd: '10013160/270421/0247729',
				country: 'Турция',
				backInput: {
					input: {
						text: '',
						disabled: false,
						error: 'Не указано'
					}
				},
				reason: {
					reason: {
						text: 'Брак',
						// error: 'Не указана причина',
						error: '',
						details: '',
						images: [''],
					}
				},
				delete: {
					delete: {
						text: 'Удалить'
					}
				}
			},
			{
				uid: '124',
				checkbox: {
					checkbox: {
						checked: false,
						disabled: true
					}
				},
				number: 1,
				brand: {
					label: 'BREMHOF',
					link: {
						text: 'HY9371',
						url: '#'
					}
				},
				product: {
					label: 'Фильтр гидравлический SF-FILTER',
					add:{
						tooltip: 'Фильтр гидравлический SF-FILTER'
					}
				},
				cnt: 16,
				price: 200.45,
				backcnt: 16,
				summ: 20000.45,
				order: {
					timestamp: '2021-08-30T07:23:33+0000',
					link: {
						text: '364259547',
						url: '#'
					}
				},
				gtd: '10013160/270421/0247729',
				country: 'Турция',
				backInput: {
					input: {
						text: '',
						disabled: false,
						error: ''
					}
				},
				reason: {
					reason: {
						text: 'Брак',
						details: '',
						images: [''],
						error: ''
					}
				},
				delete: {
					delete: {
						text: 'Удалить'
					}
				}
			},
			{
				uid: '125',
				checkbox: {
					checkbox: {
						checked: true,
						disabled: false
					}
				},
				number: 1,
				brand: {
					label: 'BREMHOF',
					link: {
						text: 'HY9371',
						url: '#'
					}
				},
				product: {
					label: 'Фильтр гидравлический SF-FILTER',
					add:{
						tooltip: 'Фильтр гидравлический SF-FILTER'
					}
				},
				cnt: 16,
				price: 200.45,
				backcnt: 16,
				summ: 20000.45,
				order: {
					timestamp: '2021-08-30T07:23:33+0000',
					link: {
						text: '364259547',
						url: '#'
					}
				},
				gtd: '10013160/270421/0247729',
				country: 'Турция',
				backInput: {
					input: {
						text: '',
						disabled: true,
						// error: 'Срок истек'
						error: ''
					}
				},
				reason: {
					reason: {
						text: 'Брак',
						details: '',
						images: [''],
						error: ''
					}
				},
				delete: {
					delete: {
						text: 'Удалить'
					}
				}
			}
		],
		totals: {
			total: {
				checkbox: 'Всего',
				cnt: '1000',
				backcnt: '1000',
				summ: '2045200.45',
				reason: '50 на 2 045 200.45 руб.'
			}
		}
	}

	constructor() {
		makeAutoObservable(this)
		for (let i = 0; i < this.dataTable.rows.length; i++) {
			reaction(
				() => this.dataTable.rows[i].backInput.input.text,
				debounce(() => {
					console.log('reacting to input change ' + this.dataTable.rows[i].backInput.input.text)
					runInAction(() => {
						if (this.dataTable.rows[i].backInput.input.text === '') {
							this.dataTable.rows[i].backInput.input.error = 'Не указано'
						}
						else {
							this.dataTable.rows[i].backInput.input.error = ''
						}
					})
					console.log('errors' + this.errors)
				}, 500)
			)
		}
	}

	get errors() {
		for (let i = 0; i < this.dataTable.rows.length; i++) {
			if (this.dataTable.rows[i].backInput.input.error !== '' || this.dataTable.rows[i].reason.reason.error !== '') return true
		}
		return false
	}

	get checks() {
		let checksList = [] as any
		for (let i = 0; i < this.dataTable.rows.length; i++) {
			if (this.dataTable.rows[i].checkbox.checkbox.checked) {
				checksList.push(this.dataTable.rows[i].uid)
			}
		}
		return checksList
	}

	handleImages = (title: string, url: string, delete_url: string) => {
		this.uploadedImages.push({
			title: title,
			url: url,
			delete_url: delete_url
		})
	}

	handleInput = (uid: string, input: string) => {
		console.log('changed')
		const inputRowIndex = this.dataTable.rows.findIndex(el => el.uid === uid)
		this.dataTable.rows[inputRowIndex].backInput.input.text = input
	}

	toggleCheckbox = (name: string, checked: boolean) => {
		const index = this.dataTable.rows.findIndex(row => row.uid === name)
		runInAction(() => {
			this.dataTable.rows[index].checkbox.checkbox.checked = checked
		})
	}
}

export default CreateReturnStore
