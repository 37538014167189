// скрипт, скрипта, скриптов
export const declOfNum = (number: number, words: string[]): string => {
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10 : 5]]
}

// сортировка объекта по массиву, например
/*
    settings = ['num', 'cnt', 'price']
    object = {
        uid: '1',
        cnt: 123,
        country: 'Ваканда',
        price: 111
    }
    на выходе
    ['', 123, 111]

 */
export const sortObjectByArray = (object: any, settings: string[], getKeys = false) => {
    const keyValue = Object.entries(object)
    return settings.map((settingsKey: string) => {
            let entryInObject = keyValue.find(([key, val]) => key === settingsKey)
            if (entryInObject) {
                return entryInObject[1]
            } else return ''
        }
    )
}

//объект для отправки
export const generateFormData = (data: any) => {
    const bodyFormData = new FormData()
    for (const key in data) {
        if ('object' !== typeof data[key]) {
            bodyFormData.append(`${key}`, data[key])
        }
        else {
            for (const keyChildren in data[key]) {
                bodyFormData.append(`${key}[${keyChildren}]`, data[key][keyChildren])
            }
        }
    }
    return bodyFormData
}

//время вида hh:mm из числовой переменной
export const formatHours = (hours: number) => {
    if (hours < 10) {
        return '0' + hours + ':00'
    }
    else return hours + ':00'
}

export const weekDayStringArray = [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье'
]
