import React, {FC, useEffect, useRef, useState} from 'react'
import {ModalProps} from '../../components/Modal/types'
import Header from '../../components/Modal/components/Header/Header'
import Message from '../../components/Modal/components/Message/Message'

import style from './DefaultLayout.module.scss'

const DefaultLayout: FC<ModalProps> = ({
    title,
    description,
    message,
    className,
    popupClass,
    typeHeader,
    setState,
    children
}) => {
    const {popup, active} = style
    const [classList, setClassList] = useState([popup])
    useEffect(() => {
        setClassList([popup, active])
    }, [active, popup])

    const containerRef = useRef<HTMLDivElement>(null)
    const bodyRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const closeHandler = (e: any) => bodyRef.current!.contains(e.target) || setState(false)
        if (containerRef.current && bodyRef.current) {
            containerRef.current.addEventListener('click', closeHandler)
            return () => document.removeEventListener('click', closeHandler)
        }
    }, [setState])

    return (
        <div className={`${classList.join(' ')} ${popupClass ? style['popup__' + popupClass] : ''}`} ref={containerRef}>
            <div className={style.popup__content}>
                <div className={`${style.popup__body} ${className ? className : ''}`} ref={bodyRef}>
                    <Header title={title} description={description} typeHeader={typeHeader}
                            setState={setState}/>
                    {message && <Message text={message}/>}
                    {children}
                </div>
            </div>
        </div>
    )
}

export default DefaultLayout