import {makeAutoObservable, reaction, runInAction, toJS} from 'mobx'

type chartOptionsType = {
	chart: {
		type: string
	},
	title: {
		text: string
	},
	xAxis: {
		categories: string[],
		lineWidth: number
	},
	yAxis: {
		min: number,
		stackLabels: {
			enabled: boolean,
			style: {
				fontWeight: string,
				color: string
			}
		}
	},
	tooltip: {
		headerFormat: string,
		pointFormat: string
	},
	plotOptions: {
		column: {
			stacking: string,
			dataLabels: {
				enabled: boolean
			},
			pointPadding: number,
			groupPadding: number
		}
	},
	legend: {
	}
	series?: {
		name: string,
		data: number[],
		visible: boolean
	}[],
	colors: string[]
}

class StatsStore {

	chartSeriesBtns = [] as any[]


	chartOptions: chartOptionsType = {
		chart: {
			type: 'column'
		},
		title: {
			text: 'Stacked column chart'
		},
		xAxis: {
			categories: ['11.10', '12.10', '13.10', '14.10', '15.10', '16.10', '17.10', '18.10', '19.10', '20.10', '21.10', '22.10', '23.10', '24.10', '25.10', '26.10', '27.10', '28.10', '29.10'],
			lineWidth: 0
		},
		yAxis: {
			min: 0,
			stackLabels: {
				enabled: false,
				style: {
					fontWeight: 'bold',
					color: 'gray'
				}
			}
		},
		tooltip: {
			headerFormat: '<b>{point.x}</b><br/>',
			pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
		},
		plotOptions: {
			column: {
				stacking: 'normal',
				dataLabels: {
					enabled: true
				},
				pointPadding: 0,
				groupPadding: 0.04
			}
		},
		legend: {
			floating: true,
			align: 'right',
			x: -30,
			verticalAlign: 'top',
			y: 25
		},
		series: [
			{
				name: 'Продажи',
				data: [12, 412, 23, 112, 123, 244, 123, 24, 56, 134, 553, 54, 455, 654, 334, 12, 56, 124, 123],
				visible: true
			},
			{
				name: 'Недопоставки',
				data: [45, 34, 1, 55, 12, 123, 54, 23, 67, 345, 23, 12, 57, 75, 34, 65, 34, 673, 123],
				visible: true
			},
			{
				name: 'Возвраты',
				data: [423, 33, 213, 31, 44, 231, 14, 66, 123, 56, 34, 122, 56, 34, 123, 0, 35],
				visible: true
			},
			{
				name: 'Отказы',
				data: [1, 65, 33, 534, 23, 56, 465, 12, 57, 78, 432, 123, 56, 0, 214, 653, 34, 62, 1],
				visible: true
			}
		],
		colors: ['#A8D2E4', '#FDBCA1', '#7FC8A9', '#FFC978']
	}

	constructor() {
		makeAutoObservable(this)
	}

	toggleChartSeriesVisibility = (index: number) => {

	}

}

export default StatsStore