import {makeAutoObservable, runInAction} from 'mobx'
import {fetchCarts} from '../api'
import {fetchCartProducts} from '../api/basket'
import {TCart} from '../types/TCart'

class CartStore {
    cartsCount = 5
    cart = {
        uid: 1,
        name: 'Коризночка номер один'
    }
    dataTable: TCart | null = null
    loadingCart: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    renameCart = (text: string) => {
        runInAction(() => {
            this.cart.name = text
        })
    }

    getCartProducts = async () => {
        await runInAction(() => {
            this.loadingCart = true
            fetchCartProducts()
                .then(res => {
                    this.dataTable = res
                    this.loadingCart = false
                })
                .catch(err => console.log(err))
        })
    }

    getCarts = async () => {
        await runInAction(() => {
            this.loadingCart = true
            fetchCarts()
                .then(res => {
                    this.dataTable = res
                    this.loadingCart = false
                })
                .catch(err => console.log(err))
        })
    }


}

export default CartStore
