import React, {lazy, Suspense} from 'react'
import {Route, Switch} from 'react-router-dom'
import {observer} from 'mobx-react'
import Preloader from './components/Preloader/Preloader'

const AuthPage = lazy(() => import('./pages/AuthPage/AuthPage'))
const RegistrationPage = lazy(() => import('./pages/RegistrationPage/RegistrationPage'))

const MainPage = lazy(() => import('./pages/MainPage/MainPage'))
const ProfilePage = lazy(() => import('./pages/ProfilePage/ProfilePage'))
const DocumentsPage = lazy(() => import('./pages/DocumentsPage/DocumentsPage'))
const DocumentUpdPage = lazy(() => import('./pages/DocumentUpdPage/DocumentUpdPage'))
const DocumentUkdPage = lazy(() => import('./pages/DocumentUkdPage/DocumentUkdPage'))
const OrdersPage = lazy(() => import('./pages/OrdersPage/OrdersPage'))
const OrderPage = lazy(() => import('./pages/OrderPage/OrderPage'))
const ReturnsPage = lazy(() => import('./pages/ReturnsPage/ReturnsPage'))
const CreateReturnPage = lazy(() => import('./pages/CreateReturnPage/CreateReturnPage'))
const ReturnRequestPage = lazy(() => import('./pages/ReturnRequestPage/ReturnRequestPage'))
const CartPage = lazy(() => import('./pages/CartPage/CartPage'))
const CheckoutPage = lazy(() => import('./pages/CheckoutPage/CheckoutPage'))
const CartsListPage = lazy(() => import('./pages/CartsListPage/CartsListPage'))
const CatalogPage = lazy(() => import('./pages/CatalogPage/CatalogPage'))
const SearchPage = lazy(() => import('./pages/SearchPage/SearchPage'))
const SearchResultsPage = lazy(() => import('./pages/SearchResultsPage/SearchResultsPage'))
const SearchResultsProductsPage = lazy(() => import('./pages/SearchResultsProductsPage/SearchResultsProductsPage'))
const PriceListsPage = lazy(() => import('./pages/PriceListsPage/PriceListsPage'))
const SellerOrders = lazy(() => import('./pages/SellerOrdersPage/SellerOrdersPage'))
const SellerOrder = lazy(() => import('./pages/SellerOrderPage/SellerOrderPage'))
const SellerShipmentPage = lazy(() => import('./pages/SellerShipmentPage/SellerShipmentPage'))
const SellerReturnOrdersPage = lazy(() => import('./pages/SellerReturnOrdersPage/SellerReturnOrdersPage'))
const SellerReturnsPage = lazy(() => import('./pages/SellerReturnsPage/SellerReturnsPage'))
const SellerReturnPage = lazy(() => import('./pages/SellerReturnPage/SellerReturnPage'))
const SellerReturnOrderPage = lazy(() => import('./pages/SellerReturnOrderPage/SellerReturnOrderPage'))
const SellerDocumentsPage = lazy(() => import('./pages/SellerDocumentsPage/SellerDocumentsPage'))
const SellerDocUploadPage = lazy(() => import('./pages/SellerDocUploadPage/SellerDocUploadPage'))
const StatsPage = lazy(() => import('./pages/StatsPage/StatsPage'))
const NotFoundPage = lazy(() => import('./pages/NotFoundPage/NotFoundPage'))

const Routes = observer(() => {
    if (process.env.REACT_APP_VERSION === 'client') {
        return (
            <Suspense fallback={<Preloader/>}>
                <Switch>
                    <Route exact path='/' component={MainPage}/>

                    <Route exact path='/auth' component={AuthPage}/>
                    <Route path='/registration' component={RegistrationPage}/>

                    <Route exact path='/personal/documents' component={DocumentsPage}/>
                    <Route exact path='/personal/documents/:id' component={DocumentUpdPage}/>
                    <Route exact path='/personal/documents/:id/:id' component={DocumentUkdPage}/>

                    <Route exact path='/personal/orders' component={OrdersPage}/>
                    <Route exact path='/personal/orders/:id' component={OrderPage}/>

                    <Route exact path='/personal/returns' component={ReturnsPage}/>
                    <Route exact path='/personal/returns/create' component={CreateReturnPage}/>
                    <Route exact path='/personal/returns/:id' component={ReturnRequestPage}/>

                    <Route path='/personal' component={ProfilePage}/>

                    <Route exact path='/cart' component={CartPage}/>
                    <Route exact path='/carts/:cart' component={CartPage}/>
                    <Route exact path='/carts' component={CartsListPage}/>

                    <Route path='/checkout' component={CheckoutPage}/>

                    {/*<Route exact path='/catalog' component={CatalogPage}/>*/}

                    <Route exact path='/search' component={SearchPage}/>
                    <Route exact path='/catalog/' component={SearchResultsPage}/>
                    <Route exact path='/catalog/:article' component={SearchResultsProductsPage}/>
                    <Route exact path='/catalog/products' component={SearchResultsProductsPage}/>

                    <Route exact path='/loading'><Preloader fixed/></Route>

                    <Route component={NotFoundPage}/>
                </Switch>
            </Suspense>
        )
    }
    if (process.env.REACT_APP_VERSION === 'provider') {
        return (
            <Suspense fallback={<Preloader/>}>
                <Switch>

                    <Route exact path='/' component={AuthPage}/>
                    <Route exact path='/auth' component={AuthPage}/>
                    <Route exact path='/seller/price-lists' component={PriceListsPage}/>
                    <Route exact path='/seller/orders' component={SellerOrders}/>
                    <Route exact path='/seller/orders/:id' component={SellerOrder}/>
                    <Route exact path='/seller/shipment' component={SellerShipmentPage}/>
                    <Route exact path='/seller/return-orders' component={SellerReturnOrdersPage}/>
                    <Route exact path='/seller/return-orders/:id' component={SellerReturnOrderPage}/>
                    <Route exact path='/seller/returns/' component={SellerReturnsPage}/>
                    <Route exact path='/seller/returns/:id' component={SellerReturnPage}/>
                    <Route exact path='/seller/documents/' component={SellerDocumentsPage}/>
                    <Route exact path='/seller/documents/upload' component={SellerDocUploadPage}/>
                    <Route exact path='/seller/stats/' component={StatsPage}/>
                    <Route path='/seller/personal' component={ProfilePage}/>
                </Switch>
            </Suspense>
        )
    }
    if (process.env.REACT_APP_VERSION === 'admin') {
        return (
            <Suspense fallback={<Preloader/>}>
                <Switch>
                    <Route exact path='/' component={() => <p>Админ 🤩</p>}/>
                </Switch>
            </Suspense>
        )
    }
    return null
})

export default Routes
