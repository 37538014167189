import React from 'react'
import LinkItem from './LinkItem/LinkItem'

type LinkListProps = {
    data: {url: string, text: string}[]
}

const LinkList = ({data}: LinkListProps) => {
    return (
        <>
            {
                data.map(({url, text}, index) =>
                    <LinkItem key={index} url={url} text={text}/>
                )
            }
        </>
    )
}

export default LinkList
