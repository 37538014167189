import React, {FormEvent, useContext, useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import StoreContext from '../../../../../stores/StoreContext'
import {observer} from 'mobx-react'
import AutocompleteResults from './AutocompleteResults'
import SearchInput from './SearchInput'
import {debounce} from '@material-ui/core/utils'

import styles from '../Search.module.scss'

export const StylesContext = React.createContext(styles)

const Search = observer(() => {
    const inputText = React.useRef<HTMLInputElement>(null)
    const stores = useContext(StoreContext)
    const [query, setQuery] = useState('')
    const history = useHistory()
    const [toggleSearchPopover, setToggleSearchPopover] = useState(false)

    const submit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        stores?.searchStore.getDetails(inputText && inputText.current ? inputText.current.value : '', '1', 20, 'N', 'Y')
        history.push(`/catalog/?q=${inputText && inputText.current ? inputText.current.value : ''}`)
        clearAndClose()
    }


    const changeHandle = (requestString: string) => {
        setQuery(requestString)
        if (requestString.length > 0) {
            setToggleSearchPopover(true)
        }
        else clearAndClose()
        if (stores) {
            stores.searchStore.getDetails(requestString, '1', 20, 'Y', 'N')
            //debounce(() => stores.searchStore.getDetails(requestString, '1', 20, 'Y', 'N'), 500)()
            // stores.searchStore.getCategories(requestString)
        }
    }
    const clearAndClose = () => {
        if (stores) {
            // stores.searchStore.detailsData.details = []
            stores.searchStore.categoriesData.categories = []
        }
        setToggleSearchPopover(false)
    }

    return (
        <StylesContext.Provider value={styles}>
            {stores &&
                <ClickAwayListener
                    mouseEvent='onMouseDown'
                    touchEvent='onTouchStart'
                    onClickAway={() => clearAndClose()}>
                    <div className={`${styles.search} ${toggleSearchPopover ? styles.active : ''}`}>
                        <SearchInput ref={inputText}
                            onChange={debounce(changeHandle, 500)} onSubmit={submit}/>
                        {toggleSearchPopover && <AutocompleteResults close={clearAndClose} query={query}/>}
                    </div>
                </ClickAwayListener>}
        </StylesContext.Provider>
    )
})

export default Search
