import React, {FC} from 'react'
import {observer} from 'mobx-react'
import {Virtuoso} from 'react-virtuoso'
import DetailItem from './DetailItem'
import styles from '../../Search.module.scss'
import {Link} from 'react-router-dom'

type DetailsListProps = {
	store: any
	close: any
	query: string
}

const DetailsList:FC<DetailsListProps> = observer(({store, close, query}) => {
	if (store.detailsData.details.length === 0) return <div>Ничего не найдено</div>
	else return (
		<>
			<Virtuoso
				style={{ height: "336px", }}
				data={store.detailsData.details}
				itemContent={(index, item) =>
					<DetailItem
						key={item.id}
						id={item.id}
						name={item.name}
						img={item.img}
						url={item.url}
						close={close}/>}
			/>
			<Link to={`/catalog/?q=${query}`} className={styles.searchCount} onClick={close}>Всего найдено: {store.detailsData.nav.count}</Link>
		</>
	)
})

export default DetailsList