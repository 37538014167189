import {makeAutoObservable, reaction, runInAction, toJS} from 'mobx'
import axios from 'axios'
import {
	deletePriceList,
	fetchPriceListDetect,
	fetchPriceLists,
	fetchPriceListSettings, setPriceLocation,
	startPriceListProcessing,
	turnOnPriceListLog,
} from '../api/price'
import {IPriceListState} from '../types/IPriceListState'
import IPushNotification from '../types/IPushNotification'

type PriceListItem = {
	ID: string
	name: string
	file: {
		url: string
		size: string
		name: string
	}
	date_add: string
	date_mod: string
	settings: any
	moderate: string
	notice: string
	active: string
	status: string
	log?: {
		info: {
			add: number
			cnt: number
			dp: number
			max: number
			nm: number
			skip: number
			upd: number
		}
	}
	delivery: Array<{
		weekDay: number
		hours: Array<{
			delivery: number
			delivery_hours: number
			from: number
		}>
	}>
}

class PriceListsStore {
	priceListsStates: Array<IPriceListState> = []
	notifications: Array<IPushNotification> = []
	uploadStatus = 'wait'
	uploadStatusMsg = ''
	uploadPercent = 0
	uploadErrors = undefined
	deliveryObj = null
	modalId: undefined | string = undefined
	uploadCnt: number | undefined = undefined
	uploadSkip: number | undefined = undefined

	priceListsTableColumns = [
		'Название проайс-листа',
		'Файл',
		'Город',
		'Обновление',
		'',
		'Сроки поставки',
		'Вкл./выкл. прайс',
		''
	]
	duplicateActive = 0
	duplicateOptions = ['skip', 'stock', 'price']
	duplicateValues = ['Пропустить', 'С большим количеством', 'С большей ценой']
	colError: string | undefined = undefined
	colOptions = ['---', 'name', 'article', 'brand', 'stock', 'price']
	currentColOptions: string[] = []
	priceListColSelectOptions: string[][] = []
	priceListCols: number[] = []

	priceListsLoading = true
	priceLists: Array<PriceListItem> = [
	]

	priceListTable = {
		head: [],
		table: [[]],
		loading: true,
		id: undefined as string | undefined
	}

	deliveryTimeColumns = [
		'День недели',
		'Время заказа',
		'Срок поставки',
		'День и время доставки'
	]

	deliveryTimeDaysList = [
		'0',
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
		'11',
		'12',
		'13',
		'14',
		'15'
	]
	deliveryTimeHoursList = [
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
		'10',
		'11',
		'12',
		'13',
		'14',
		'15',
		'16',
		'17',
		'18',
		'19',
		'20',
		'21',
		'22',
		'23'
	]

	constructor() {
		makeAutoObservable(this)
		reaction(
			() => this.priceListCols.map((item, index) => this.priceListCols[index]),
			() => {
				console.log('REACTION ' + this.priceListCols)
			}
		)
	}

	startUpload = (priceId?: string) => {
		runInAction(() => {
			if (priceId) {
				let state = this.priceListsStates.find(item => item.id === priceId)
				if (state) {
					state.uploadStatus = 'loading'
					console.log(priceId + ' uploadStatus = \'loading\'')
				}
			}
			else {
				this.uploadStatus = 'loading'
			}
			console.log('this.uploadStatus = \'loading\'')
		})
		this.bxPullFile(priceId)
	}

	bxPullFile = (priceId?: string) => {
		window.BX.PULL.subscribe({
			moduleId: 'file',
			callback: (data: any) => {
				console.log('BX.PULL.subscribe file', data);
				try {
					if (data.command === 'error') {
						console.log("Ошибка! " + data.params.msg);
						runInAction(() => {
							let state = this.priceListsStates.find(item => item.id === priceId)
							if (state) {
								state.uploadStatus = 'fail'
								state.uploadErrors = data.params.msg
							}
							else {
								this.uploadStatus = 'fail'
								this.uploadErrors = data.params.msg
							}
						})
					} else {
						if (data.command === 'process') {
							runInAction(() => {
								let state = this.priceListsStates.find(item => item.id === priceId)
								if (state) {
									state.uploadStatusMsg = data.params.msg
								}
								else {
									this.uploadStatusMsg = data.params.msg
								}
							})
						}
						if (data.params.info) {
							console.log(data.params.info);
						}
					}
				} catch (error) {
					console.log('BX.PULL.subscribe fiel - ERR', error);
					runInAction(() => {
						console.log('this.uploadStatus = \'fail\'')
						let state = this.priceListsStates.find(item => item.id === priceId)
						if (state) {
							state.uploadStatus = 'fail'
							state.uploadErrors = error.toString()
						}
						else {
							this.uploadStatus = 'fail'
							this.uploadErrors = error.toString()
						}
					})
				}
			}
		});
	}

	bxPullProcess = (priceId?: string) => {
		window.BX.PULL.subscribe({
			moduleId: 'price',
			callback: (data: any) => {
				console.log('BX.PULL.subscribe price', data);
				try {
					this.initNotifications()
					if (data.command == 'success') {
						console.log("[#" + data.params.priceId + "] " + data.params.msg);
					}
					if (data.command == 'process') {
						console.log("[#" + data.params.priceId + "] " + data.params.msg);

						if (data.params.info) {
							console.log(data.params.info);
							runInAction(() => {
								console.log('priceId = ' + data.params.priceId)
								let indexPrice = this.priceLists.findIndex((item) => item.ID === data.params.priceId.toString())
								let indexState = this.priceListsStates.findIndex((item) => item.id === data.params.priceId.toString())

								this.priceLists[indexPrice].status = 'processed'
								this.priceLists[indexPrice].log = data.params
								console.log(toJS(this.priceLists[indexPrice]))

								this.priceListsStates[indexState].uploadStatusMsg = data.params.msg

								this.priceListsStates[indexState].uploadCnt = data.params.info.cnt
								this.priceListsStates[indexState].uploadSkip = data.params.info.skip

								let percent = Math.round((data.params.info.cnt * 100) / data.params.info.max)
								if (!isNaN(percent)) {
									this.priceListsStates[indexState].uploadPercent = percent
								}

								if (data.params.info.cnt === data.params.info.max && data.params.info.cnt !== 0) {
									// после обработки всех строк коллбэк все ещё вызывается, так что проверяем
									// дополнительно что уже поставили статус
									if (this.priceListsStates[indexState].uploadStatus !== 'success') {
										this.priceListsStates[indexState].uploadStatus = 'success'
										console.log('this.uploadStatus = \'success\'')
										this.priceListTable = {
											head: [],
											table: [[]],
											loading: true,
											id: undefined as string | undefined
										}
									}
								}
							})
							this.updateNotifications()
						}
					}
					if (data.command == 'error') {
						let msgType = "Ошибка! " + data.params.msg;
//                    if (data.params.msg == 'dp') {
//                        msgType = "Дубликат в существующем прайсе";
//                    }
//                    if (data.params.msg == 'dp-price') {
//                        msgType = "Дубликат в существующем прайсе";
//                    }
						console.log("[#" + data.params.priceId + "] "
							+ msgType + "; "
							+ ((data.params.info) ? "Строка: " + data.params.info.cnt + "; " : "")
							+ ((data.params.obj) ? data.params.obj.UF_NAME + " / " : "")
							+ ((data.params.obj) ? data.params.obj.UF_ARTICLE : ""));

						// runInAction(() => {
						// 	if (priceId) {
						// 		let state = this.priceListsStates.find(item => item.id === data.params.priceId.toString())
						// 		if (state) {
						// 			state.uploadStatus = 'fail'
						// 			state.uploadErrors = data.params.msg
						// 			console.log('this.uploadStatus = \'fail\'')
						// 		}
						// 	}
						// 	else {
						// 		this.uploadStatus = 'fail'
						// 		console.log('this.uploadStatus = \'fail\'')
						// 		this.uploadErrors = data.params.msg
						// 	}
						// })
					}
				} catch (e) {
					console.log('BX.PULL.subscribe price - ERR', e);
					runInAction(() => {
						if (priceId) {
							let state = this.priceListsStates.find(item => item.id === data.params.priceId.toString())
							if (state) {
								state.uploadStatus = 'fail'
								state.uploadErrors = e
								console.log('this.uploadStatus = \'fail\'')
							}
						}
						else {
							this.uploadStatus = 'fail'
							console.log('this.uploadStatus = \'fail\'')
							this.uploadErrors = e
						}
					})
				}
			}
		});
	}

	uploadPriceList = async (file: any, name: string, fileName: string, locationId: string, priceId?: string) => {
		console.log(
			{
				file,
				name,
				fileName,
				locationId,
				priceId
			}
		)
		runInAction(() => {
			if (priceId) {
				let state: IPriceListState = {
					id: priceId,
					uploadStatus: 'started',
					uploadStatusMsg: '',
					uploadPercent: 0,
					uploadErrors: undefined,
					deliveryObj: null,
					uploadCnt: undefined,
					uploadSkip: undefined,
				}
				this.addPriceState(state)

			}
			this.uploadStatus = 'started'
			console.log('this.uploadStatus = \'started\'')
		})
		let formData = new FormData();
		formData.append('sessid', window.BX.message('bitrix_sessid'));
		formData.append("name", name);
		formData.append("fName", fileName);
		formData.append("locationId", locationId);
		if (this.modalId) {
			formData.append("priceId", this.modalId);
			console.log('Прайс лист #' + this.modalId + " / " + name + " - будет обновлён");
		}
		formData.append("file", file, file.name);
		console.log(formData)
		console.log('Отправка файла начата');

		// this.bxPullFile(priceId)
		this.startUpload(priceId)

		runInAction(() => {
			if (priceId) {
				let state = this.priceListsStates.find(item => item.id === priceId)
				if (state) {
					state.uploadStatus = 'loading'
					state.uploadStatusMsg = 'Загрузка файла'
				}
			}
			else {
				this.uploadStatus = 'loading'
				console.log('this.uploadStatus = \'loading\'')
				this.uploadStatusMsg = 'Загрузка файла'
			}

		})

		await axios({
			data: formData,
			method: "POST",
			url: "/api/price/upload",
			onUploadProgress: (progressEvent: ProgressEvent) => {
				console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total))
				// console.log(progressEvent.loaded + ' из ' + progressEvent.total)
				runInAction(() => {
					if (priceId) {
						let state = this.priceListsStates.find(item => item.id === priceId)
						if (state) {
							state.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
						}
						this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
					}
					else {
						this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
					}
				})
			},
			timeout: 60000
		})
			.then((data: any) => {
				console.log(data);
				if (data.data.status === 'success') {
					console.log('Отправка файла завершена');

					let state: IPriceListState = {
						id: data.data.data.priceId.toString(),
						uploadStatus: 'started',
						uploadStatusMsg: '',
						uploadPercent: 0,
						uploadErrors: undefined,
						deliveryObj: null,
						uploadCnt: undefined,
						uploadSkip: undefined,
					}
					this.addPriceState(state)

					runInAction(() => {
						let state = this.priceListsStates.find(item => item.id === data.data.data.priceId.toString())
						console.log(state)
						if (state) {
							state.uploadStatus = 'uploaded'
							this.uploadStatus = 'wait'
							this.modalId = data.data.data.priceId.toString()
							console.log('modal id = ' + this.modalId)
						}
						console.log('this.uploadStatus = \'uploaded\'')
					})
					// this.getPriceListTable(data.data.data.priceId, true)
				} else {
					runInAction(() => {
						console.log('ПЕРЕД ОШИБКОЙ')
						console.log(toJS(this.priceListsStates))
						console.log(data)
						let state = this.priceListsStates.find(item => item.id === data.data.data.priceId)
						if (state) {
							state.uploadStatus = 'fail'
						}
						console.log('this.uploadStatus = \'fail\'')
					})
					console.log('Ошибка при отправке файла ' + console.log(data));
				}
			})
			.catch(error => {
				runInAction(() => {
					console.log('this.uploadStatus = \'fail\'')
					if (priceId) {
						let state = this.priceListsStates.find(item => item.id === priceId)
						if (state) {
							state.uploadStatus = 'fail'
							state.uploadErrors = error.toString()
						}
					}
					else {
						this.uploadStatus = 'fail'

						this.uploadErrors = error.toString()
					}
				})
				console.log(toJS(error));
				console.log('Ошибка при отправке файла! ' + error);
			})
	}

	validateCols = () => {
		let isError = false

		runInAction(() => {
			for (let i = 1; i < 6; i++) {
				if (this.priceListCols.findIndex((item) => item === i) === -1) {
					isError = true
				}
			}

			if (isError) {
				this.colError = 'Нужно разметить'
			}
			else {
				this.colError = undefined
			}
		})
	}

	processPriceList = async () => {
		this.validateCols()
		if (!this.colError) {
			let formData = new FormData();
			let colRequestString = ''
			this.priceListCols.map((item, index) => {
				let value = this.colOptions[item]
				if (value === '---') {
					value = ''
				}
				colRequestString = colRequestString + 'col[' + index + ']=' + value + '&'
			})
			colRequestString = colRequestString + 'duplicate=' + this.duplicateOptions[this.duplicateActive]

			formData.append('cols', colRequestString);
			if (this.priceListTable.id) {
				formData.append("priceId", this.priceListTable.id);
			}
			formData.append('sessid', window.BX.message('bitrix_sessid'));

			await axios({
				data: formData,
				method: "POST",
				url: "/api/price/setSettings"
			})
				.then(r => {
					console.log(r)
					let id = this.modalId
					if (id !== undefined) {
						let formData = new FormData()
						formData.append("priceId", id)
						startPriceListProcessing(formData)
							.then(r => console.log(r))
						turnOnPriceListLog(formData)
						this.getPriceLists(() => {
							runInAction(() => {
								let price = this.priceLists.find(item => item.ID === id)
								let state = this.priceListsStates.find(item => item.id === id)

								console.log('price:')
								console.log(toJS(price))

								if (state) {
									if (price && price.status === 'moderate') {
										state.uploadStatus = 'moderate'
									}
									else {
										state.uploadPercent = 0
										state.uploadStatus = 'loading'
										this.bxPullProcess(id)
										console.log('this.uploadStatus = \'loading\'')
									}
								}
							})
						})
					}
				})
				.catch(err => console.log(err))
		}

	}

	getPriceLists = async (callback: any = () => {}) => {
		runInAction(() => {
			this.priceListsLoading = true
			fetchPriceLists()
				.then(r => {
					this.priceLists = r.data.data
					console.log(toJS(r.data.data))
					this.createPriceStatesArray()
					console.log(toJS(this.priceListsStates))
					this.bxPullProcess()
					this.bxPullFile()
					this.priceListsLoading = false
					callback()
				})
				.catch(err => console.log(err))
		})
	}

	deletePriceList = (id: number) => {
		this.priceListsLoading = true
		let formData = new FormData();
		formData.append('sessid', window.BX.message('bitrix_sessid'))
		formData.append("priceId", id.toString())
		deletePriceList(formData)
			.then(r => {
				console.log(r)
				this.priceListsLoading = false
				this.getPriceLists()
			})
			.catch(err => console.log(err))
	}

	getPriceListTable = (id: string, update?: boolean) => {
		let formData = new FormData();
		formData.append("priceId", id);
		runInAction(() => {
			if (update) {
				this.priceListTable.loading = true
			}
			fetchPriceListDetect(formData)
				.then(r => {
					this.priceListTable.table = r.data.data.table
					this.priceListTable.head = r.data.data.head
					this.priceListTable.id = id
					this.setUpColsOptions(r.data.data.detect)
					this.priceListTable.loading = false

				})
				.catch(err => console.log(err))
		})
		if (update) {
			this.getPriceLists()
		}
	}

	setUpColsOptions = (detected?: any) => {
		runInAction(() => {
			this.currentColOptions = this.colOptions
			this.priceListColSelectOptions = []

			for (let i = 0; i < this.priceListTable.table[0].length; i++) {
				this.priceListCols.push(0)
				let options = this.colOptions.slice()
				this.priceListColSelectOptions.push(options)
			}

			if (detected) {
				for (let i = 0; i < this.priceListCols.length; i++) {
					let optionIndex = this.colOptions.findIndex(item => item === Object.keys(detected).find(key => detected[key] === i))
					if (optionIndex > -1) {
						this.changeColSelect(optionIndex, i)
					}
					else {
						this.changeColSelect(0, i)
					}
				}
			}

			console.log('cols selects set up')
			console.log(toJS(this.priceListColSelectOptions))
		})
	}

	changeDeliveryDays = (dateOptionIndex: number, priceIndex: number, weekDay: number, hoursIndex: number) => {
		this.priceLists[priceIndex].delivery[weekDay].hours[hoursIndex].delivery = parseInt(this.deliveryTimeDaysList[dateOptionIndex])
	}

	changeDeliveryHours = (hoursOptionIndex: number, priceIndex: number, weekDay: number, hoursIndex: number) => {
		this.priceLists[priceIndex].delivery[weekDay].hours[hoursIndex].delivery_hours = parseInt(this.deliveryTimeHoursList[hoursOptionIndex])
	}

	updateDeliveryDate = (fromHours: number, days: number, hours: number, weekDay: number) => {
		return new Date(93*60*60*1000 + weekDay*24*60*60*1000 + days*24*60*60*1000 + fromHours*60*60*1000 + hours*60*60*1000)
	}

	changeColSelect = (selectedIndex: number, colIndex: number) => {
		runInAction(() => {
			this.priceListCols[colIndex] = selectedIndex
		})
	}

	changeDuplicateSelect = (index: number) => {
		runInAction(() => {
			this.duplicateActive = index
		})
	}

	setDeliveryTime = async (deliveryObject: any, id: string) => {
		if (this.deliveryObj) {
			console.log('delivery data')
			console.log(id)
			console.log(deliveryObject)
			let formData = new FormData()
			formData.append('priceId', id)
			formData.append('deliveryJson', JSON.stringify(deliveryObject))
			formData.append('sessid', window.BX.message('bitrix_sessid'))
			axios.post('/api/price/setDelivery', formData)
				.then(r => {
					console.log(r)
					// this.setToDefault()
					this.getPriceLists()
					// this.setToDefault()
					// let state = this.priceListsStates.find(item => item.id === id.toString())
					// if (state) {
					// 	state.uploadStatus = 'wait'
					// }
				})
				.catch(err => console.log(err))
		}
	}

	togglePriceListActivity = async (id: string, activity: string, priceList: PriceListItem, setError: any) => {
		let active = activity === 'Y' ? 'N' : 'Y'
		let formData = new FormData()
		formData.append('priceId', id)
		formData.append('active', active)
		priceList.active = 'D'
		axios.post('/api/price/active', formData)
			.then(r => {
				console.log(r)
				if (r.data.status === 'error') {
					setError(r.data.errors[0].message)
					priceList.active = activity
				}
				else {
					priceList.active = active
				}
			})
			.catch(err => console.log(err))
	}

	setToDefault = () => {
		runInAction(() => {
			this.priceListTable = {
				head: [],
				table: [[]],
				loading: true,
				id: undefined as string | undefined
			}

			this.uploadStatus = 'wait'
			this.uploadStatusMsg = ''
			this.uploadPercent = 0
			this.uploadErrors = undefined
			this.deliveryObj = null
			this.modalId = undefined
			this.uploadCnt = undefined
			this.uploadSkip = undefined
		})
	}

	createPriceStatesArray = () => {
		this.priceLists.forEach((item) => {
			if (this.priceListsStates.findIndex((state) => state.id === item.ID.toString()) === -1) {
				let state: IPriceListState = {
					id: item.ID.toString(),
					uploadStatus: 'wait',
					uploadStatusMsg: '',
					uploadPercent: 0,
					uploadErrors: undefined,
					deliveryObj: null,
					uploadCnt: undefined,
					uploadSkip: undefined,
				}
				this.addPriceState(state)
			}
		})
		this.clearPriceStatesArray()
		this.initNotifications()
	}

	clearPriceStatesArray = () => {
		let itemsToDelete: Array<number> = []
		//если нашли состояние для которого нет прайса - заносим его индекс в список на удаление
		this.priceListsStates.forEach((state, index) => {
			if (this.priceLists.findIndex((price) => state.id === price.ID.toString()) === -1) {
				itemsToDelete.push(index)
			}
		})
		itemsToDelete.forEach((index) => {
			this.priceListsStates.splice(index, 1)
		})
	}

	addPriceState = (state: IPriceListState) => {
		if (this.priceListsStates.findIndex((item) => item.id === state.id) === -1) {
			runInAction(() => {
				this.priceListsStates.push(state)
			})
		}
	}

	isDeliverySet = (id: string) => {
		let price = this.priceLists.find((item: any) => item.ID === id.toString())
		let sum = 0
		if (price) {
			price.delivery.map((day: any) => {
				day.hours.map((item: any) => {
					sum = sum + item.delivery_hours + item.delivery
				})
			})
		}
		return sum > 0
	}

	setLocation = (id: string, bx_id: string) => {
		this.priceListsLoading = true
		let formData = new FormData();
		formData.append('sessid', window.BX.message('bitrix_sessid'))
		formData.append("priceId", id.toString())
		formData.append("locationId", bx_id)
		setPriceLocation(formData)
			.then(r => {
				console.log(r)
				this.priceListsLoading = false
				this.getPriceLists()
			})
			.catch(err => console.log(err))
	}

	initNotifications = () => {
		this.notifications = []
		this.priceLists.forEach((item: PriceListItem) => {
			if (item.status === 'processed' && !(item.status === 'processed' && (item.log?.info.cnt === item.log?.info.max))) {
				this.notifications.push({
					priceId: item.ID,
					type: 'update',
					text: 'Прайс #' + item.ID + ' обновляется  — ' + Math.round(((item.log?.info.cnt || 0) * 100 / (item.log?.info.max || 0))) + '%.' +
						' Загружено: ' + item.log?.info.cnt || 0 + ', пропущено:' + item.log?.info.skip || 0
				} as IPushNotification)
			}
			if (item.status === 'success' || (item.status === 'processed' && (item.log?.info.cnt === item.log?.info.max))) {
				this.notifications.push({
					priceId: item.ID,
					type: 'success',
					text: 'Прайс лист #' + item.ID + ' успешно обновлен. Загружено: ' + item.log?.info.cnt || 0 + ', пропущено:' + item.log?.info.skip || 0
				} as IPushNotification)
			}
			else if (item.status === 'error') {
				this.notifications.push({
					priceId: item.ID,
					type: 'error',
					text: this.priceListsStates.find(state => state.id === item.ID)?.uploadErrors
				} as IPushNotification)
			}
		})
	}

	updateNotifications = () => {
		this.priceLists.forEach((item: PriceListItem) => {
			let notifIndex = this.notifications.findIndex(n => n.priceId === item.ID)
			if (notifIndex !== -1) {
				if (item.status === 'processed' && !(item.status === 'processed' && (item.log?.info.cnt === item.log?.info.max))) {
					this.notifications[notifIndex] = {
						priceId: item.ID,
						type: 'update',
						text: 'Прайс #' + item.ID + ' обновляется  — ' + Math.round(((item.log?.info.cnt || 0) * 100 / (item.log?.info.max || 0))) + '%.' +
							' Загружено: ' + item.log?.info.cnt || 0 + ', пропущено:' + item.log?.info.skip || 0
					} as IPushNotification
				}
				else if (item.status === 'success' || (item.status === 'processed' && (item.log?.info.cnt === item.log?.info.max))) {
					this.notifications[notifIndex] = {
						priceId: item.ID,
						type: 'success',
						text: 'Прайс #' + item.ID + ' лист успешно обновлен. Загружено: ' + item.log?.info.cnt || 0 + ', пропущено:' + item.log?.info.skip || 0
					} as IPushNotification
				}
				else if (item.status === 'error') {
					this.notifications[notifIndex] = {
						priceId: item.ID,
						type: 'error',
						text: this.priceListsStates.find(state => state.id === item.ID)?.uploadErrors
					} as IPushNotification
				}
			}
		})
	}

	deleteNotification = (index: number) => {
		if (index > -1) {
			this.notifications.splice(index, 1)
		}
	}

}

export default PriceListsStore