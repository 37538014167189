import React, {FC} from 'react'
import style from './Message.module.scss'

type MessageProps = {
    text: string
}

const Message: FC<MessageProps> = ({text}) => {
    return (
        <div className={style.popup__message}>{text}</div>
    )
}

export default Message