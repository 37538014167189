import React from 'react'

type IconCarProps = {className?: string}

const IconCar = ({className} : IconCarProps) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
            <path d="M20.5781 6.1875L19.4062 2.625C18.8906 1.07812 17.4375 0 15.8438 0H8.10938C6.51562 0 5.0625 1.07812 4.54688 2.625L3.375 6.1875C1.875 6.65625 0.75 8.0625 0.75 9.75V15.75C0.75 17.0156 1.73438 18 3 18H3.75C4.96875 18 6 17.0156 6 15.75V15H18V15.75C18 17.0156 18.9844 18 20.25 18H21C22.2188 18 23.25 17.0156 23.25 15.75V9.75C23.25 8.0625 22.0781 6.65625 20.5781 6.1875ZM6 3.09375C6.28125 2.15625 7.125 1.5 8.10938 1.5H15.8438C16.8281 1.5 17.6719 2.15625 17.9531 3.09375L18.9375 6H5.01562L6 3.09375ZM4.5 15.75C4.5 16.1719 4.125 16.5 3.75 16.5H3C2.57812 16.5 2.25 16.1719 2.25 15.75V15H4.5V15.75ZM21.75 15.75C21.75 16.1719 21.375 16.5 21 16.5H20.25C19.8281 16.5 19.5 16.1719 19.5 15.75V15H21.75V15.75ZM21.75 12V13.5H2.25V9.75C2.25 8.53125 3.23438 7.5 4.5 7.5H19.5C20.7188 7.5 21.75 8.53125 21.75 9.75V12ZM5.625 8.625C4.40625 8.625 3.5625 9.42188 3.5625 10.5C3.5625 11.625 4.40625 12.375 5.625 12.375H6.09375C7.96875 12.375 8.25 11.4375 8.25 10.875C8.25 9.75 6.75 8.625 5.625 8.625ZM6.09375 11.25C5.95312 11.25 5.76562 11.25 5.625 11.25C5.0625 11.25 4.6875 10.9688 4.6875 10.5C4.6875 10.0781 5.0625 9.75 5.625 9.75C6.23438 9.75 7.125 10.4531 7.125 10.875C7.125 11.25 6.60938 11.25 6.09375 11.25ZM18.3281 8.625C17.2031 8.625 15.75 9.75 15.75 10.875C15.75 11.4375 15.9844 12.4219 17.8594 12.4219L18.3281 12.375C19.5469 12.375 20.4375 11.625 20.4375 10.5C20.4375 9.42188 19.5469 8.625 18.3281 8.625ZM18.3281 11.25C18.1875 11.25 18 11.25 17.8594 11.25C17.3438 11.25 16.875 11.25 16.875 10.875C16.875 10.4531 17.7188 9.75 18.3281 9.75C18.8906 9.75 19.3125 10.0781 19.3125 10.5C19.3125 10.9688 18.8906 11.25 18.3281 11.25Z" fill="#313640"/>
        </svg>
    )
}

export default IconCar
