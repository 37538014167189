import {makeAutoObservable, runInAction, reaction} from 'mobx'

import {fetchDetails, fetchOrders} from '../api'
import debounce from '@material-ui/core/utils/debounce'

enum Sort {
    none,
    ascend,
    descend
}

class OrdersStore {
    searchQuery = 0
    filterOpen = false
    currentTable = 'orders'
    supplySort = Sort.none
    dataTable = {
        settings: {
            desktop: [''],
            mobile: ['']
        },
        rows: [],
        loading: true
    }
    filters = {
        for: {
            active: 0,
            options: [
                {
                    label: 'Номер заказа',
                    table: 'orders'
                },
                {
                    label: 'Деталь',
                    table: 'details'
                }
            ]
        },
        search: {
            active: 0,
            options: [
                {
                    placeholder: 'Искать номер заказа',
                    onChange: () => {}
                },
                {
                    placeholder: 'Искать деталь',
                    onChange: () => {}
                },
            ]
        },
        orderNumber: 'dffs31413',
        supplyDateRange: {
            currentDate: new Date(),
            start: new Date(),
            end: new Date(),
        },
        creationDateRange: {
            currentDate: new Date(),
            start: new Date(),
            end: new Date(),
        },
        status: {
            active: 2,
            options: ['Любой', 'ТДСТ/МСК/3749/ОО, от 20.12.2021', 'ТДСТ/МСК/3749/ОО, от 20.12.2021']
        },
        contract: {
            active: 2,
            options: ['ТДСТ/МСК/3749/ОО, от 20.12.2021', 'ТДСТ/МСК/3749/ОО, от 20.12.2021', 'ТДСТ/МСК/3749/ОО, от 20.12.2021']
        },
        checkboxes: [
            {
                label: 'Самовывоз',
                checked: false,
                name: 'buy'
            },
            {
                label: 'Доставка',
                checked: true,
                name: 'return'
            },
            {
                label: 'Транспортная компания',
                checked: false,
                name: 'ds'
            },
            {
                label: 'Четвертый чекбокс',
                checked: false,
                name: 'rrr'
            },
            {
                label: 'Пятый чекбокс',
                checked: false,
                name: 'rrr5'
            },
            {
                label: 'Шестой чекбокс',
                checked: false,
                name: 'rrr6'
            },
            {
                label: 'Седьмой чекбокс',
                checked: false,
                name: 'rrr7'
            },
            {
                label: 'Восьмой чекбокс',
                checked: false,
                name: 'rrr8'
            },
            {
                label: 'Девятый чекбокс',
                checked: false,
                name: 'rrr9'
            }
        ],
        author: {
            active: 1,
            options: ['Любой', 'Кто то', 'Чел']
        },

        found: 12
    }
    pagination = {
        page: 0,
        limit: 30,
        size: 400
    }

    get paginationSelect() {
        const options = ['10', '20', '30']
        const perPage = options.findIndex(item => parseInt(item) === this.pagination.limit)
        return {
            perPage: perPage,
            options: ['10', '20', '30']
        }
    }

    constructor() {
        makeAutoObservable(this)
        reaction(
            () => this.searchQuery,
            debounce(() => {
                console.log('debounced: ' + this.searchQuery)
            }, 500))
    }

    getTable = async (table: string) => {
        if (table === 'orders') {
            await runInAction(() => {
                this.dataTable.loading = true
                fetchOrders()
                    .then(res => {
                        this.dataTable = res
                        this.dataTable.loading = false
                    })
                    .catch(err => console.log(err))
            })
        }
        if (table === 'details') {
            await runInAction(() => {
                this.dataTable.loading = true
                fetchDetails()
                    .then(res => {
                        this.dataTable = res
                        this.dataTable.loading = false
                    })
                    .catch(err => console.log(err))
            })
        }

    }

    toggleSupplySort = () => {
        if (this.supplySort < 2)
            this.supplySort++
        else
            this.supplySort = 0
    }

    toggleFilter = () => {
        this.filterOpen = !this.filterOpen
    }

    setCreationDateRangeStart = (start: Date) => {
        this.filters.creationDateRange.start = start
    }

    setCreationDateRangeDate = (date: Date) => {
        this.filters.creationDateRange.currentDate = date
    }

    setCreationDateRangeEnd = (end: Date) => {
        this.filters.creationDateRange.end = end
    }

    setSupplyDateRangeStart = (start: Date) => {
        this.filters.supplyDateRange.start = start
    }

    setSupplyDateRangeDate = (date: Date) => {
        this.filters.supplyDateRange.currentDate = date
    }

    setSupplyDateRangeEnd = (end: Date) => {
        this.filters.supplyDateRange.end = end
    }

    toggleCheckbox = (name: string, checked: boolean) => {
        const index = this.filters.checkboxes.findIndex(el => el.name === name)
        this.filters.checkboxes[index].checked = checked
    }

    toggleFilterTab = (index: number) => {
        this.filters.for.active = index
        this.filters.search.active = index
        this.currentTable = this.filters.for.options[this.filters.for.active].table
    }

}



export default OrdersStore
