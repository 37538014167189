import {makeAutoObservable, runInAction, toJS} from 'mobx'
import {fetchSearchedDetails} from '../api/catalog'

class SearchStore {
	infinite = false
	query?: string = undefined
	hasNextPage = true
	detailsData = {
		loading: false,
		details: [],
		nav: {
			count: 0,
			page: '0',
			size: 0,
			pages: 0
		}
	}
	categoriesData = {
		loading: false,
		categories: []
	}
	sortOptions: {label: string, active: boolean}[] = []
	dataTable = {
		settings: {
			desktop: [],
			mobile: [],
			sort: []
		},
		rows: [],
		nav: {
			count: 0,
			page: '0',
			size: 0,
			pages: 0
		},
		loading: false
	}

	dataInfiniteTable = {
		settings: {
			desktop: [],
			mobile: []
		},
		rows: [] as any[],
		nav: {
			count: 0,
			page: '0',
			size: 0,
			pages: 0
		},
		loading: true
	}

	filterOpen = false
	filters = {
		contract: {
			active: 2,
			options: ['ТДСТ/МСК/3749/ОО, от 20.12.2021', 'ТДСТ/МСК/3749/ОО, от 20.12.2021', 'ТДСТ/МСК/3749/ОО, от 20.12.2021']
		},
		checkboxesStorage: [
			{
				label: 'Москва',
				checked: false,
				name: 'moscow'
			},
			{
				label: 'Новосибирск',
				checked: true,
				name: 'novosib'
			},
			{
				label: 'Пенза',
				checked: false,
				name: 'penza'
			},
			{
				label: 'Санкт-Петербург',
				checked: false,
				name: 'spb'
			},
			{
				label: 'Томск',
				checked: false,
				name: 'tomsk'
			}
		],
		checkboxesBrand: [
			{
				label: 'BALDWIN',
				checked: false,
				name: '1'
			},
			{
				label: 'BALDWIN',
				checked: true,
				name: '2'
			},
			{
				label: 'BALDWIN',
				checked: false,
				name: '3'
			},
			{
				label: 'BALDWIN',
				checked: true,
				name: '4'
			},
			{
				label: 'BALDWIN',
				checked: false,
				name: '5'
			}
		],
		checkboxOfficialDealer: [
			{
				label: 'Официальный дилер (20)',
				checked: false,
				name: 'dealer'
			}
		],
		checkboxesScrew: [
			{
				label: 'Гайка 1',
				checked: false,
				name: '1'
			},
			{
				label: 'Гайка 2',
				checked: true,
				name: '2'
			},
			{
				label: 'Гайка 3',
				checked: false,
				name: '3'
			},
			{
				label: 'Гайка 4',
				checked: false,
				name: '4'
			},
			{
				label: 'Гайка 5',
				checked: true,
				name: '5'
			},
			{
				label: 'Гайка 6',
				checked: false,
				name: '6'
			}
		],
		size: {
			from: '',
			to: ''
		},
		atmospheres: {
			from: '',
			to: ''
		},
		productLength: {
			from: '',
			to: ''
		},
		found: 12
	}

	constructor() {
		makeAutoObservable(this)
	}

	get paginationSelect() {
		const options = ['5', '20']
		const perPage = options.findIndex(item => parseInt(item) === this.dataTable.nav.size)
		return {
			perPage: perPage,
			options: ['5', '20']
		}
	}

	loadMore = (indexStart: number, indexStop: number) => {
		console.log('index start = ' + indexStart)
		const page = Math.ceil((indexStart - 1) / 20)
		console.log('page = ' + page)
		this.dataInfiniteTable.loading = true

		return	fetchSearchedDetails(this.query ? this.query : '', page.toString(), 20, 'Y', 'Y')
				.then(res => {
					// console.log(res)
					if (res.data.nav.count - 1 <= indexStart) {
						this.hasNextPage = false
					}
					this.dataInfiniteTable.settings = res.data.settings
					this.dataInfiniteTable.nav = res.data.nav
					this.dataInfiniteTable.rows = [...this.dataInfiniteTable.rows, ...res.data.rows]
					console.log(toJS(this.dataInfiniteTable.rows))
					this.dataInfiniteTable.loading = false
				})

	}

	getDetails = async (requestString: string, page?: string, size?: number, fast?: 'Y' | 'N', table?: 'Y' | 'N') => {
		await runInAction(() => {
			table === 'Y' ? this.dataTable.loading = true : this.detailsData.loading = true
			fetchSearchedDetails(requestString, page, size, fast, table)
				.then(res => {
					if (table === 'Y') {
						this.dataTable = res.data

						// if (this.dataTable.settings.sort.length > 0 && this.sortOptions.length === 0) {
						// 	this.dataTable.settings.sort.map(item =>
						// 		this.sortOptions.push({
						// 			label: sortTitles[item],
						// 			active: false
						// 		})
						// 	)
						// }
						this.dataTable.loading = false
					}
					else {
						this.detailsData.details = res.data.items
						this.detailsData.nav = res.data.nav
						this.detailsData.loading = false
					}

					console.log(res)

				})
				.catch(err => console.log(err))
		})
	}

	toggleFilter = () => {
		this.filterOpen = !this.filterOpen
	}

	toggleStorageCheckbox = (name: string, checked: boolean) => {
		const index = this.filters.checkboxesStorage.findIndex(el => el.name === name)
		this.filters.checkboxesStorage[index].checked = checked
	}

	toggleBrandCheckbox = (name: string, checked: boolean) => {
		const index = this.filters.checkboxesBrand.findIndex(el => el.name === name)
		this.filters.checkboxesBrand[index].checked = checked
	}

	toggleScrewCheckbox = (name: string, checked: boolean) => {
		const index = this.filters.checkboxesScrew.findIndex(el => el.name === name)
		this.filters.checkboxesScrew[index].checked = checked
	}

	toggleOfficialDealerCheckbox = (name: string, checked: boolean) => {
		const index = this.filters.checkboxOfficialDealer.findIndex(el => el.name === name)
		this.filters.checkboxOfficialDealer[index].checked = checked
	}

	sortBy = (col: string) => {
		runInAction(() => {
			if (this.sortOptions.length > 0) {
				let selectedIndex = this.sortOptions.findIndex(item => item && item.label === col)
				this.sortOptions.map(item => item.active = false)
				if (selectedIndex >= 0) {
					this.sortOptions[selectedIndex].active = true
				}
			}
		})
	}
}

export default SearchStore