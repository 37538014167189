import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import Container from '../../../layouts/Container/Container'
import Logo from '../../Logo/Logo'
import Burger from './components/Burger'
import {StylesContext} from '../Header'
// import StoreContext from '../../../stores/StoreContext'
import Location from './components/Location/Location'
import UserMenu from './components/UserMenu/UserMenu'
import ReturnsMenu from './components/ReturnsMenu/ReturnsMenu'

const TopBar = () => {
    // const stores = useContext(StoreContext)
    const styles = useContext(StylesContext)
    return (
        <header className={styles.header}>
            <Container>
                <div className={styles['header__top--flex']}>
                    <Logo className={styles.header__logo}/>
                    <div className={styles.header__basic}>
                        <Link to='/catalog/' className={`${styles['header__basic-item']} ${styles.catalogs}`}>
                            <div className={styles.catalogs__icon}><span/></div>
                            <div className={styles.catalogs__text}>Каталоги</div>
                        </Link>
                        <Link to={'#'} className={styles['header__basic-item']}>Сопутствующие товары</Link>
                        <Location/>
                    </div>
                    <div className={styles.header__addit}>
                        <Link to='/personal/orders' className={styles['header__addit-item']}>Заказы</Link>
                        <ReturnsMenu/>
                        <Link to='/personal/documents' className={styles['header__addit-item']}>Документы</Link>
                    </div>
                    <div className={styles.header__interaction}>
                        <UserMenu/>
                        <Burger/>
                    </div>
                </div>
            </Container>
        </header>
    )
}

export default TopBar
