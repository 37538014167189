import {makeAutoObservable, runInAction} from 'mobx'
import {fetchReturns} from '../api'

class ReturnsStore {
    filterOpen = false
    dataTable = {
        settings: [],
        rows: [],
        loading: true
    }
    filters = {
        supplyDateRange: {
            currentDate: new Date(),
            start: new Date(),
            end: new Date(),
        },
        contract: {
            active: 2,
            options: ['ТДСТ/МСК/3749/ОО, от 20.12.2021', 'ТДСТ/МСК/3749/ОО, от 20.12.2021', 'ТДСТ/МСК/3749/ОО, от 20.12.2021']
        },
        found: 4
    }

    pagination = {
        page: 0,
        limit: 30,
        size: 400
    }

    get paginationSelect() {
        const options = ['10', '20', '30']
        const perPage = options.findIndex(item => parseInt(item) === this.pagination.limit)
        return {
            perPage: perPage,
            options: ['10', '20', '30']
        }
    }

    constructor() {
        makeAutoObservable(this)
    }

    getReturns = async () => {
        await runInAction(() => {
            this.dataTable.loading = true
            fetchReturns()
                .then(res => {
                    this.dataTable = res
                    this.dataTable.loading = false
                })
                .catch(err => console.log(err))
        })
    }

    toggleFilter = () => {
        this.filterOpen = !this.filterOpen
    }

    setSupplyDateRangeStart = (start: Date) => {
        this.filters.supplyDateRange.start = start
    }

    setSupplyDateRangeDate = (date: Date) => {
        this.filters.supplyDateRange.currentDate = date
    }

    setSupplyDateRangeEnd = (end: Date) => {
        this.filters.supplyDateRange.end = end
    }
}

export default ReturnsStore
