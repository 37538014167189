import React from 'react'
import RootStore from './RootStore'
import DocumentsStore from './DocumentsStore'
import StaticStore from './StaticStore'
import OrdersStore from './OrdersStore'
import OrderStore from './OrderStore'
import ReturnsStore from './ReturnsStore'
import ReturnRequestStore from './ReturnRequestStore'
import DocumentUpdStore from './DocumentUpdStore'
import DocumentUkdStore from './DocumentUkdStore'
import CreateReturnStore from './CreateReturnStore'
import CartStore from './CartStore'
import HeaderStore from './HeaderStore'
import ProfileStore from "./ProfileStore";
import CatalogStore from './CatalogStore'
import PersonalStore from './PersonalStore'
import SearchStore from './SearchStore'
import NotificationsStore from './NotificationsStore'
import PriceListsStore from './PriceListsStore'
import SellerOrdersStore from './SellerOrdersStore'
import SellerOrderStore from './SellerOrderStore'
import SellerShipmentStore from './SellerShipmentStore'
import SellerReturnOrdersStore from './SellerReturnOrdersStore'
import SellerReturnOrderStore from './SellerReturnOrderStore'
import SellerDocumentsStore from './SellerDocumentsStore'
import SellerUploadDocumentStore from './SellerUploadDocumentStore'
import SellerReturnsStore from './SellerReturnsStore'
import SellerReturnStore from './SellerReturnStore'
import StatsStore from './StatsStore'
import SearchResultsStore from './SearchResultsStore'
import CheckoutStore from './CheckoutStore'

interface StoreContextInterface {
    rootStore: RootStore
    headerStore: HeaderStore
    documentsStore: DocumentsStore
    documentUpdStore: DocumentUpdStore
    documentUkdStore: DocumentUkdStore
    ordersStore: OrdersStore
    orderStore: OrderStore
    returnsStore: ReturnsStore
    createReturnStore: CreateReturnStore
    returnRequestStore: ReturnRequestStore
    cartStore: CartStore
    profileStore: ProfileStore
    catalogStore: CatalogStore
    personalStore: PersonalStore
    staticStore: StaticStore
    searchStore: SearchStore
    searchResultsStore: SearchResultsStore
    notificationsStore: NotificationsStore
    priceListsStore: PriceListsStore
    sellerOrdersStore: SellerOrdersStore
    sellerOrderStore: SellerOrderStore
    sellerShipmentStore: SellerShipmentStore
    sellerReturnOrdersStore: SellerReturnOrdersStore
    sellerReturnOrderStore: SellerReturnOrderStore
    sellerDocumentsStore: SellerDocumentsStore
    sellerUploadDocumentStore: SellerUploadDocumentStore
    sellerReturnsStore: SellerReturnsStore
    sellerReturnStore: SellerReturnStore
    statsStore: StatsStore
    checkoutStore: CheckoutStore
}

const StoreContext = React.createContext<StoreContextInterface | null>(null)

export default StoreContext
