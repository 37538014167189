import React, {useContext} from 'react'

import IconCar from '../../../Svg/IconCar'
import IconSupport from '../../../Svg/IconSupport'

import {StylesContext} from '../../Header'
import {Link} from 'react-router-dom'

const ExtraSearchLinks = () => {
    const styles = useContext(StylesContext)
    return (
        <div className={styles.headerBottom__extra}>
            <div className={styles['headerBottom__extra-item']}>
                <IconCar className={styles['headerBottom__extra-icon']}/>
                <Link to='/search/' className={styles['headerBottom__extra-text']}>Поиск по VIN/кузову</Link>
            </div>
            <div className={styles['headerBottom__extra-item']}>
                <IconSupport className={styles['headerBottom__extra-icon']}/>
                <Link to='#' className={styles['headerBottom__extra-text']}>Помощь в подборе</Link>
            </div>
        </div>
    )
}

export default ExtraSearchLinks
