import axios from 'axios'

//скачать пример прайс-листа
export const fetchTemplatePrice = async () => await axios.get('/api/price/template')

//удалить прайс-лист
export const deletePriceList = async (formData: FormData) => await axios.post('/api/price/delete', formData)

//получить информацию о прайс-листе
export const fetchPriceListSettings = async (formData: FormData) => await axios.post('/api/price/settings', formData)

//получить колонки прайс-листа
export const fetchPriceListDetect = async (formData: FormData) => await axios.post('/api/price/detect', formData)

//споисок прайс-листов
export const fetchPriceLists = async () => await axios.get('/api/price/list')

//запустить обработку прайс листа
export const startPriceListProcessing = async (formData: FormData) => await axios.post('/api/price/start', formData)

//включить подробный вывод лога в режиме реального времени
export const turnOnPriceListLog = async (formData: FormData) => await axios.post('/api/price/log', formData)

//включить подробный вывод лога в режиме реального времени
export const setPriceLocation = async (formData: FormData) => await axios.post('/api/price/setLocation', formData)