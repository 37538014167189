import {makeAutoObservable, runInAction} from 'mobx'
import {fetchOrderDetails} from '../api'

class OrderStore {
    generalInfo = [
        {
            label: 'Дата создания',
            info: {
                text: '18.05.2021 16:40:18'
            }
        },
        {
            label: 'Поставка',
            info: {
                text: '18.05.2021 — 18.05.2021'
            }
        },
        {
            label: 'Договор',
            info: {
                text: '0000350050'
            }
        },
        {
            label: 'Сумма по заказу',
            info: {
                text: '10 000 руб.'
            }
        },
        {
            label: 'Способ получения',
            info: {
                text: 'Доставка'
            }
        },
        {
            label: 'Пункт выдачи',
            info: {
                text: 'ЦРС Москва'
            }
        },
        {
            label: 'Статус',
            info: {
                status: 'red',
                text: 'Отклонен'
            }
        },
        {
            label: 'Доступно к возврату',
            info: {
                text: 'до 18.05.2021'
            }
        }
    ]
    dataTable = {
        settings: {
            desktop: [
                'brand',
                'product',
                'cnt',
                'inwork',
                'ready',
                'shipped',
                'doc',
                'supply',
                'price',
                'summ',
            ],
            mobile: [
                'brand',
                'cnt',
                'inwork',
                'ready',
                'shipped',
                'doc',
                'supply',
                'price',
                'summ',
            ]
        },
        rows: [
            {
                uid: '123',
                product: 'Фильтр гидравлический SF-FILTER',
                brand: {
                    label: 'BREMHOF',
                    link: {
                        text: 'HY9371',
                        url: '#'
                    }
                },
                cnt: 16,
                inwork: 16,
                ready: 16,
                shipped: 12,
                doc: {
                    label: 'Накладная',
                    link: {
                        text: '№45466 от 12.12.2021',
                        url: '#'
                    }
                },
                price: 200.45,
                summ: 2785800.45,
                supply: {
                  dateRange: {
                      from: "2021-08-26T13:22:46.125Z",
                      to: "2021-08-26T13:22:46.125Z"
                  }
                }
            },
            {
                uid: '123',
                rejected: true,
                product: {
                    text: 'Фильтр гидравлический SF-FILTER',
                    tooltip: 'Фильтр гидравлический SF-FILTER'
                },
                brand: {
                    label: 'BREMHOF',
                    link: {
                        text: 'HY9371',
                        url: '#'
                    }
                },
                cnt: 16,
                price: 200.45,
                inwork: {
                    text: 'Отклонено',
                    style: 'red'
                },
            },
            {
                uid: '123',
                product: 'Фильтр гидравлический SF-FILTER',
                brand: {
                    label: 'BREMHOF',
                    link: {
                        text: 'HY9371',
                        url: '#'
                    }
                },
                cnt: 16,
                inwork: 16,
                ready: 16,
                shipped: 12,
                doc: {
                    label: 'Накладная',
                    link: {
                        text: '№45466 от 12.12.2021',
                        url: '#'
                    }
                },
                price: 200.45,
                summ: 2785800.45,
                supply: {
                    dateRange: {
                        from: "2021-08-26T13:22:46.125Z",
                        to: "2021-08-26T13:22:46.125Z"
                    }
                }
            }
        ],
        totals: {
            inorder: {
                brand: 'В заказе',
                product: '7 позиций',
                cnt: '1000',
                summ: '2785800.45'
            },
            rejected: {
                brand: 'Отклонено',
                product: '1 позиция',
                cnt: '1000',
                summ: '2785800.45'
            },
            total: {
                brand: 'Всего',
                product: '6 позиций',
                cnt: '1000',
                summ: '2785800.45'
            }
        },
        loading: true
    }
    total = 2045200.45

    constructor() {
        makeAutoObservable(this)
    }

    getDetails = async () => {
        await runInAction(() => {
            this.dataTable.loading = true
            fetchOrderDetails()
                .then(res => {
                    this.dataTable = res
                    this.dataTable.loading = false
                })
                .catch(err => console.log(err))
        })
    }
}

export default OrderStore
