import React from 'react'

type IconArrowProps = {className?: string}

const IconArrow = ({className} : IconArrowProps) => {
    return (
        <svg className={className} width="11" height="6" viewBox="0 0 11 6" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.71875 5.875C5.875 6.03125 6.09375 6.03125 6.25 5.875L10.875 1.28125C11.0312 1.15625 11.0312 0.90625 10.875 0.75L10.25 0.15625C10.125 0 9.875 0 9.71875 0.15625L6 3.84375L2.25 0.15625C2.09375 0 1.875 0 1.71875 0.15625L1.09375 0.75C0.9375 0.90625 0.9375 1.15625 1.09375 1.28125L5.71875 5.875Z"/>
        </svg>
    )
}

export default IconArrow
