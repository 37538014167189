import {makeAutoObservable, runInAction} from 'mobx'
import {fetchSellerReturn} from '../api'

type uploadStatusType = 'error' | 'success' | undefined

class SellerReturnStore {
	// uploadStatus: uploadStatusType = 'success'
	// uploadedFileName = 'КФС.xls'

	uploadStatus: uploadStatusType = undefined
	uploadedFileName = undefined

	dataTable = {
		settings: {},
		rows: [],
		loading: true
	}

	generalInfo = [
		{
			label: 'Контрагент',
			info: {
				text: 'Название контрагента'
			}
		},
		{
			label: 'Пункт выдачи',
			info: {
				text: 'Москва-склад'
			}
		},
		{
			label: 'Вид возврата',
			info: {
				text: 'Недовоз'
			}
		},
		{
			label: 'Основание',
			info: {
				text: 'счет-фактура №9453753 от 12.12.2021'
			}
		},
		{
			label: 'Сумма на возврат',
			info: {
				text: '2 045 200.45 руб.'
			}
		},
		{
			label: 'Сумма НДС',
			info: {
				text: '2 045 200.45 руб.'
			}
		}
	]

	constructor() {
		makeAutoObservable(this)
	}

	getReturn = async () => {
		runInAction(() => {
			this.dataTable.loading = true
			fetchSellerReturn()
				.then(r => {
					this.dataTable = r.data
					this.dataTable.loading = false
				})
				.catch(err => console.log(err))
		})
	}

}

export default SellerReturnStore