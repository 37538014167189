import React, {FC} from 'react'
import style from './Header.module.scss'
import {ModalProps} from '../../types'

const Header:FC<ModalProps> = ({title, description, typeHeader, setState}) => {
    if (typeHeader === 'bg') {
        return (
            <div className={style.popup__head}>
                <div className={style.popup__close} onClick={() => setState(false)}/>
                {title && <div className={style.popup__title}>{title}</div>}
                {description && <div className={style.popup__descr}>{description}</div>}
            </div>
        )
    }

    if (typeHeader === 'classic') {
        return (
            <>
                <div className={style.popup__close} onClick={() => setState(false)}/>
                {title && <div className={style.popup__title}>{title}</div>}
                {description && <div className={style.popup__descr}>{description}</div>}
            </>
        )
    }
    return null
}

export default Header