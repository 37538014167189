import React, {useContext} from 'react'
import {CitySelectContext} from './CitySelect'

type CityItemProps = {
	cityObj: any
}

const CityItem = ({cityObj}: CityItemProps) => {
	const {onSelect, styles} = useContext(CitySelectContext)
	return (
		<div className={styles['citySelect__result-item']} onMouseDown={() => onSelect(cityObj)}>
			<div className={styles['citySelect__result-text']}>{cityObj.value}</div>
		</div>
	)
}

export default CityItem