import {makeAutoObservable} from 'mobx'

class DocumentUkdStore  {
    generalInfo = [
        {
            label: 'К УПД №',
            info: {
                text: '948087482',
                url: '#'
            }
        },
        {
            label: 'Дата документа',
            info: {
                text: '18.05.2021'
            }
        },
        {
            label: 'Дата создания',
            info: {
                text: '18.05.2021 16:40:18'
            }
        },
        {
            label: 'Покупатель',
            info: {
                text: 'ООО"СпецМаш"'
            }
        },
        {
            label: 'Грузополучатель',
            info: {
                text: 'ООО"СпецМаш"'
            }
        },
        {
            label: 'Способ получения',
            info: {
                text: 'Доставка'
            }
        },
        {
            label: 'Адрес доставки',
            info: {
                text: '125438, Москва, Лихачёвский 4-й пер., 4'
            }
        },
        {
            label: 'Контактное лицо',
            info: {
                text: 'Алексеев Дмитрий'
            }
        },
        {
            label: 'УПД/Товчек',
            info: {
                text: 'ДА0460663'
            }
        }
    ]
    dataTable = {
        settings: {
            desktop: [
                'number',
                'brand',
                'product',
                'cnt',
                'price',
                'summ',
                'order',
                'gtd',
                'country'
            ],
            mobile: [
                'brand',
                'cnt',
                'price',
                'summ',
                'order',
                'gtd',
                'country'
            ]
        },
        rows: [
            {
                uid: '123',
                number: 1,
                product: 'Фильтр гидравлический SF-FILTER',
                brand: {
                    label: 'BREMHOF',
                    link: {
                        text: 'HY9371',
                        url: '#'
                    }
                },
                cnt: 16,
                price: 200.45,
                backcnt: {
                    text: 10,
                    style: 'red'
                },
                summ: 2785800.45,
                order: {
                    timestamp: '2021-08-30T07:23:33+0000',
                    link: {
                        text: '364259547',
                        url: '#'
                    }
                },
                gtd: '10013160/270421/0247729',
                country: 'Турция'
            },
            {
                uid: '123',
                number: 1,
                product: 'Фильтр гидравлический SF-FILTER',
                brand: {
                    label: 'BREMHOF',
                    link: {
                        text: 'HY9371',
                        url: '#'
                    }
                },
                cnt: 16,
                price: 200.45,
                backcnt: {
                    text: 10,
                    style: 'red'
                },
                summ: 2785800.45,
                order: {
                    timestamp: '2021-08-30T07:23:33+0000',
                    link: {
                        text: '364259547',
                        url: '#'
                    }
                },
                gtd: '10013160/270421/0247729',
                country: 'Турция'
            },
            {
                uid: '123',
                number: 1,
                product: 'Фильтр гидравлический SF-FILTER',
                brand: {
                    label: 'BREMHOF',
                    link: {
                        text: 'HY9371',
                        url: '#'
                    }
                },
                cnt: 16,
                price: 200.45,
                backcnt: {
                    text: 10,
                    style: 'red'
                },
                summ: 2785800.45,
                order: {
                    timestamp: '2021-08-30T07:23:33+0000',
                    link: {
                        text: '364259547',
                        url: '#'
                    }
                },
                gtd: '10013160/270421/0247729',
                country: 'Турция'
            },
            {
                uid: '123',
                number: 1,
                product: 'Фильтр гидравлический SF-FILTER',
                brand: {
                    label: 'BREMHOF',
                    link: {
                        text: 'HY9371',
                        url: '#'
                    }
                },
                cnt: 16,
                price: 200.45,
                backcnt: {
                    text: 10,
                    style: 'red'
                },
                summ: 2785800.45,
                order: {
                    timestamp: '2021-08-30T07:23:33+0000',
                    link: {
                        text: '364259547',
                        url: '#'
                    }
                },
                gtd: '10013160/270421/0247729',
                country: 'Турция'
            }
        ],
        totals: {
            total: {
                number: 'Всего',
                cnt: '1000',
                summ: '2045200.45'
            }
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export default DocumentUkdStore
