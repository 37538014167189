import React from 'react'

type IconLockProps = {className?: string}

const IconLock = ({className} : IconLockProps) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
            <path d="M10.125 5H9.375V3.92188C9.375 2.04688 7.875 0.523438 6 0.5C4.125 0.5 2.625 2.02344 2.625 3.875V5H1.875C1.24219 5 0.75 5.51562 0.75 6.125V11.375C0.75 12.0078 1.24219 12.5 1.875 12.5H10.125C10.7344 12.5 11.25 12.0078 11.25 11.375V6.125C11.25 5.51562 10.7344 5 10.125 5ZM3.75 3.875C3.75 2.65625 4.75781 1.625 6 1.625C7.21875 1.625 8.25 2.65625 8.25 3.875V5H3.75V3.875ZM10.125 11.375H1.875V6.125H10.125V11.375Z" fill="#F2C94C"/>
        </svg>
    )
}

export default IconLock
