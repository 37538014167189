import React, {useContext, useState} from 'react'
import {observer} from 'mobx-react'
import {StylesContext} from '../Header'
// import StoreContext from '../../../stores/StoreContext'
import IconLock from '../../Svg/IconLock'
import IconArrow from '../../Svg/IconArrow'
import IconCalendar from '../../Svg/IconCalendar'
import SelectMenu from '../../SelectMenu/SelectMenu'

// type CompanyProps = {}

const Company = observer(() => {
    const selectOptions = ['ТДСТ/МСК/1245700', 'ТДСТ/МСК/1245701', 'ТДСТ/МСК/1245702']
    // const stores = useContext(StoreContext)
    const styles = useContext(StylesContext)
    const [menuOpen, setMenuOpen] = useState(false)
    return (
        <div className={`${styles.menuMob__company} ${styles.company} ${menuOpen ? styles.open : ''}`}>
            <div className={styles.company__title}>ООО СпецМаш</div>
            <div className={styles.company__total} onClick={() => setMenuOpen(!menuOpen)}>
                <div className={`${styles['company__total-text']} color--red`}>-86 764.56 руб.</div>
                <IconArrow className={`${styles['company__total-icon']} color--red`}/>
            </div>
            <div className={`${styles.company__status} color--yellow`}>
                <IconLock className={styles['company__status-icon']}/>
                <div className={styles['company__status-text']}>Временно заблокировано</div>
            </div>
            <div className={styles.company__details}>
                <div className={styles['company__details-item']}>
                    <div className={styles['company__details-title']}>Договор</div>
                    <div className={styles.company__select}>
                        {/*<select>*/}
                        {/*    <option value="ТДСТ/МСК/374500">ТДСТ/МСК/1245700</option>*/}
                        {/*    <option value="ТДСТ/МСК/374501">ТДСТ/МСК/2045701</option>*/}
                        {/*    <option value="ТДСТ/МСК/374502">ТДСТ/МСК/3545702</option>*/}
                        {/*</select>*/}
                        <SelectMenu
                            active={0}
                            options={selectOptions}
                            handle={() => console.log('selected')}
                            minWidth='100%'
                            outline/>
                    </div>
                    <div className={styles['company__details-delay']}>
                        <IconCalendar className={styles['company__details-icon']}/>
                        <div className={styles['company__details-text']}>10 дней отсрочки</div>
                    </div>
                </div>
                <div className={styles['company__details-item']}>
                    <div className={styles['company__details-title']}>Кредит</div>
                    <div className={styles['company__details-text']}>250 000 руб.</div>
                </div>
                <div className={styles['company__details-item']}>
                    <div className={styles['company__details-title']}>Остаток</div>
                    <div className={styles['company__details-text']}>125 567.67 руб.</div>
                </div>
                <div className={styles['company__details-item']}>
                    <div className={styles['company__details-title']}>Нормальный долг</div>
                    <div className={styles['company__details-text']}>25 567.67 руб.</div>
                </div>
                <div className={styles['company__details-item']}>
                    <div className={styles['company__details-inner']}>
                        <div className={styles['company__details-title']}>Просроченый долг</div>
                        <div className="company__details-text color--red">-70 764.56 руб.</div>
                    </div>
                    <div className={styles['company__details-inner']}>
                        <div className={styles['company__details-title']}>1 — 3 дня</div>
                        <div className={styles['company__details-text']}>63 345.66 руб.</div>
                    </div>
                    <div className={styles['company__details-inner']}>
                        <div className={styles['company__details-title']}>4 — 15 дней</div>
                        <div className={`${styles['company__details-text']} color--red`}>-7 567.78</div>
                    </div>
                </div>
                <div className={styles['company__details-item']}>
                    <div className={styles['company__details-title']}>Итоговая задолженность</div>
                    <div className={`${styles['company__details-text']} color--red`}>-86 764.56 руб.</div>
                </div>
            </div>
        </div>
    )
})

export default Company
