import {makeAutoObservable, runInAction, toJS} from 'mobx'
import {fetchSearchResultsCrosses, fetchSearchResultsOffers, fetchDetailsFilters} from '../api/catalog'
import {debounce} from '@material-ui/core'
import axios from 'axios'

type SearchResultFiltersType = {
	cnt: number
	delivery_min: number
	delivery_max: number
	stock_min: number
	stock_max: number
	price_min: number
	price_max: number
	brands: [
		{
			name: string
			code: string
			cnt: number
		}
	] | string[]
}

class SearchResultsStore {
	infinite = false
	productsAll = 55
	dataTable = {
		settings: {
			desktop: [],
			mobile: [],
			sort: []
		},
		nav: {
			count: 0,
			page: 0,
			size: 0,
			pages: 0
		},
		rows: [],
		loading: false
	}

	crossesNav = {
		nav: {
			count: 0,
			page: 0,
			size: 0,
			pages: 0
		},
		set: false
	}

	dataInfiniteTable = {
		settings: {
			desktop: [
				"brand",
				"product",
				"count",
				"storage",
				"supply",
				"backDays",
				"price",
				"addToCart"
			],
			mobile: [
				"brand",
				"product",
				"count",
				"storage",
				"supply",
				"backDays",
				"price",
				"addToCartMobile"
			],
			sort: [
				"count",
				"supply"
			]
		},
		nav: {
			count: 0,
			page: 0,
			size: 0,
			pages: 0
		},
		rows: [] as any,
		loading: true
	}
	filters: SearchResultFiltersType = {
		cnt: 0,
		delivery_min: 0,
		delivery_max: 0,
		stock_min: 0,
		stock_max: 0,
		price_min: 0,
		price_max: 0,
		brands: [
			{
				name: '',
				code: '',
				cnt: 0
			}
		]
	}
	filtersToSend: SearchResultFiltersType = {
		cnt: 0,
		delivery_min: 0,
		delivery_max: 0,
		stock_min: 0,
		stock_max: 0,
		price_min: 0,
		price_max: 0,
		brands: [
			{
				name: '',
				code: '',
				cnt: 0
			}
		]
	}
	filterSet = false
	filterOpen = false
	article?: string = ''
	filter: string = ''
	order: string = ''
	mod: any = null
	hasNextPage = true
	captionSet = false
	detailsCount = 0
	loadedOneTime = false
	filterUpdatedOneTime = false
	notFound = false
	endpoint = 'detail'


	constructor() {
		makeAutoObservable(this)
	}

	setFilter = debounce((delivery: any, stock: any, price: any, brandsChecks: any) => {
		console.log('reacted')
		const brands = [] as string[]
		brandsChecks.forEach((item: {label: string, checked: boolean, name: string}) => {
			if (item.checked) {
				brands.push(item.name)
			}
		})
		this.filtersToSend = {
			cnt: this.filters.cnt,
			delivery_min: delivery[0],
			delivery_max: delivery[1],
			stock_min: stock[0],
			stock_max: stock[1],
			price_min: price[0],
			price_max: price[1],
			brands: brands
		}
		if (this.loadedOneTime) {
			this.dataInfiniteTable.nav.count = 0
			this.dataInfiniteTable.rows = []
		}
		this.captionSet = false
		this.endpoint = 'detail'
		this.filterUpdatedOneTime = true
	}, 500)

	loadMore = async (indexStart: number, indexStop: number) => {


		let page = Math.ceil((indexStart) / 20) + 1
		console.log('page = ' + page)
		console.log('start = ' + indexStart)
		this.dataInfiniteTable.loading = true

		console.log('count', this.dataInfiniteTable.nav.count)
		console.log('rows len', this.dataInfiniteTable.rows.length)
		if ((this.dataInfiniteTable.nav.count !== 0) && (this.dataInfiniteTable.nav.count === this.dataInfiniteTable.rows.length)) {
			// this.dataInfiniteTable.rows = [...this.dataInfiniteTable.rows, ...[{uid: '-1', captionRow: 'Возможные замены'}]]
			this.captionSet = true
		}
		else if (this.dataInfiniteTable.nav.count === 0) {
			this.captionSet = false
		}
		this.endpoint = this.captionSet ? 'cross' : 'detail'
		console.log('caption set', this.captionSet)
		console.log('endpoint', this.endpoint)

		if (this.endpoint === 'cross') {
			page = page - Math.ceil((this.detailsCount) / 20)
			console.log('cross page', page)
			console.log('ceil', Math.ceil((this.detailsCount) / 20))
		}

		return await axios.get(`/api/catalog/${this.endpoint}`, {
			params: {
				article: this.article ? this.article : '',
				page: page,
				size: 20,
				filter: this.filterSet ? JSON.stringify(this.filtersToSend) : '',
				order: this.order,
				mod: this.mod
			}
		})
				.then(res => {
					// console.log(this.article)
					// console.log(res.data)
					this.loadedOneTime = true
					if (this.endpoint === 'detail' && res.data.data.article === null) {
						console.log('endpoint details & article = null')
						this.notFound = true
					}
					if (res.data.data.count !== null && res.data.data.nav !== null && res.data.data.items !== null) {
						console.log(res.data.data.nav.count + ' <= ' + (indexStart ))

						if (this.endpoint === 'detail') {
							this.dataInfiniteTable.nav = res.data.data.nav
							this.detailsCount = res.data.data.nav.count

							// if ((res.data.data.nav.count + this.crossesNav.nav.count - 1 < indexStart) || (page >= Math.ceil(res.data.data.nav.count / 20))) {
							if ((res.data.data.nav.count + this.crossesNav.nav.count - 1 < indexStart)) {
								this.hasNextPage = false
								// this.dataInfiniteTable.rows = [...this.dataInfiniteTable.rows, ...[{uid: '-1', captionRow: 'Возможные замены'}]]
							}

							const rows = res.data.data.items ? Object.entries(res.data.data.items).map((item: any) => item[1]) : []
							this.dataInfiniteTable.rows = [...this.dataInfiniteTable.rows, ...rows]

							if ((this.dataInfiniteTable.nav.count !== 0) && (this.dataInfiniteTable.nav.count === this.dataInfiniteTable.rows.length)) {
								this.dataInfiniteTable.rows = [...this.dataInfiniteTable.rows, ...[{uid: '-1', captionRow: 'Возможные замены'}]]
								this.captionSet = true
							}
						}

						else {
							const count = res.data.data.nav.count + this.crossesNav.nav.count
							this.dataInfiniteTable.nav = res.data.data.nav
							this.dataInfiniteTable.nav.count = count

							const rows = res.data.data.items ? Object.entries(res.data.data.items).map((item: any) => item[1]) : []
							this.dataInfiniteTable.rows = [...this.dataInfiniteTable.rows, ...rows]

							if ((res.data.data.nav.count + this.crossesNav.nav.count - 1 < indexStart)) {
								this.hasNextPage = false
							}
						}
					}

					// this.dataInfiniteTable.rows = this.hasNextPage ? [...this.dataInfiniteTable.rows, ...rows] : [...this.dataInfiniteTable.rows, ...[{uid: '-1', captionRow: 'Возможные замены'}]]
					this.dataInfiniteTable.loading = false
				})
				.catch(err => console.log(err))
	}

	getCrossesNav = (article: string) => {
		fetchSearchResultsCrosses(article, 1, 20,  this.filter, this.order, this.mod)
			.then(res => {
				console.log('ПРИЛЕТЕВШИЕ КРОССЫ')
				console.log(res)
				this.crossesNav.nav = res.data.nav
				this.crossesNav.set = true
			})
	}

	getDetailsByArticle = async (basketItems: any, article: string, page?: number, size?: number, filter?: any, order?: string, mod?: any) => {
		console.log(toJS(basketItems), 'basket')
		runInAction(() => {
			this.dataTable.loading = true
			fetchSearchResultsOffers(article, page, size, filter, order, mod)
				.then(res => {
					console.log(res)
					// @ts-ignore
					const dataTable:any = this.getDetailsByArticleAsTable(res.data)
					// Выставляем значения по корзине
					if (basketItems) {
						dataTable.rows = dataTable.rows.map((item:any) => {
							const result = basketItems.find((basketItem:any) => basketItem.uid === item.uid)
							if (result) {
								item.addToCart.addToCart.value = result.count.counter.value
							}
							return item
						})
					}
					this.dataTable = dataTable
					this.dataTable.nav = res.data.nav
					console.log(toJS(this.dataTable))
					this.dataTable.loading = false
					// if (this.dataTable.settings.sort.length > 0 && this.sortOptions.length === 0) {
					// 	this.dataTable.settings.sort.map(item =>
					// 		this.sortOptions.push({
					// 			label: sortTitles[item],
					// 			active: false
					// 		})
					// 	)
					// }
				})
				.catch(err => console.log(err))
		})
	}

	getDetailsByArticleAsTable = (data: any) => {
		console.log('то что прилетело:')
		console.log(data)
		console.log(toJS(Object.entries(data.items)))
		const offers = data.items ? Object.entries(data.items).map((item: any) => this.formatObjectAsTableRow(item[1])) : []
		const crosses = data.crosses ? Object.entries(data.crosses).map((item: any) => this.formatObjectAsTableRow(item[1])) : []
		const captionRow = data.crosses ? [{
			uid: '-1',
			captionRow: 'Возможные замены'
		}] : []
		// @ts-ignore
		const rows = offers.concat(captionRow, crosses)

		return {
			settings: {
				"desktop":[
					"brand",
					"product",
					"count",
					"storage",
					"supply",
					"backDays",
					"price",
					"addToCart"
				],
				"mobile":[
					"brand",
					"product",
					"count",
					"storage",
					"supply",
					"backDays",
					"price",
					"addToCartMobile"
				],
				"sort":[
					"count",
					"supply"
				]
			},
			rows: rows,
			loading: false
		}
	}

	formatObjectAsTableRow = (item: any) => {
		let date = new Date()
		date.setDate(date.getDate() + parseInt(item.delivery))
		return {
			uid: item.id,
			brand: {
				label: item.brand,
				link: {
					text: item.article,
					url: item.url
				}
			},
			product: {
				label: item.name,
				add: {
					tooltip: item.name
				}
			},
			count: parseInt(item.quantity),
			storage: {
				label: item.location,
				add: {
					style: 'status-green'
				}
			},
			supply: {
				date: {
					timestamp: date
				}
			},
			backDays: 14,
			price: item.price,
			addToCart: {
				addToCart: item.counter
			},
			addToCartMobile: {
				addToCartMobile: item.counter
			}
		}
	}

	getFilters = async (article: string) => {
		fetchDetailsFilters(article)
			.then(r => {
				this.filters = {
					cnt: parseInt(r.data.cnt),
					delivery_min: parseInt(r.data.delivery_min),
					delivery_max: parseInt(r.data.delivery_max),
					stock_min: parseInt(r.data.stock_min),
					stock_max: parseInt(r.data.stock_max),
					price_min: parseFloat(r.data.price_min),
					price_max: parseFloat(r.data.price_max),
					brands: r.data.brands.map((item: {name: string, code: string, cnt: string}) => {
						return {
							name: item.name,
							code: item.code,
							cnt: parseInt(item.cnt)
						}
					})
				}
				console.log('ПРИЛЕТЕВШИЙ ФИЛЬТР')
				console.log(r.data)
				this.filterSet = true
			})
			.catch(err => console.log(err))
	}

	toggleFilter = () => {
		this.filterOpen = !this.filterOpen
	}

	get paginationSelect() {
		const options = ['5', '20']
		const perPage = options.findIndex(item => parseInt(item) === this.dataTable.nav.size)
		return {
			perPage: perPage,
			options: ['5', '20']
		}
	}

}

export default SearchResultsStore